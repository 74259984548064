import {
  ApiConfiguration,
  JwtManagerInterface,
  SentryTrackError
} from './interfaces';
import {
  fetchJwtFromEndpoint,
  JwtFetchResultType,
  JwtFetchSuccess
} from './jwtCommon';

const enum JwtStates {
  Initialisation,
  LoadingUrl,
  ValidUrlValidJWT,
  ValidUrlLoadingJWT,
  ValidUrlInvalidJWT
}

type FirstPartyUrlPartial = {
  firstPartyUrls: false | string[];
};

type InitialState = FirstPartyUrlPartial & {
  tag: JwtStates.Initialisation;
};

type UrlLoadingState = FirstPartyUrlPartial & {
  tag: JwtStates.LoadingUrl;
  completionPromise: Promise<ApiConfiguration>;
};

type UrlLoadedPartial = FirstPartyUrlPartial & {
  isApiUrlFirstParty: boolean;
  apiUrl: string;
};

type JwtLoadingState = UrlLoadedPartial & {
  tag: JwtStates.ValidUrlLoadingJWT;
  completionPromise: Promise<ApiConfiguration>;
};

type JwtValidState = UrlLoadedPartial & {
  tag: JwtStates.ValidUrlValidJWT;
  config: ApiConfiguration;
  localExpiry: number;
};

type JwtInvalidState = UrlLoadedPartial & {
  tag: JwtStates.ValidUrlInvalidJWT;
};

type JwtState =
  | InitialState
  | UrlLoadingState
  | JwtLoadingState
  | JwtValidState
  | JwtInvalidState;

export type SdkApiConfig = {
  readonly apiKey: string;
  /**
   * Acts as a fallback if all `firstPartyUrls` fail, or there are no
   *  `firstPartyUrls`.
   *
   * For example `https://prod-00.example.solvestack.net`.
   */
  readonly solveStackApiUrl: string;
  /**
   * An optional list containing all first-party URLs to try. If all fail, or
   *  there are none then use the given `apiUrl` instead. This will be a list of
   *  custom domains which are subdomains of the page we're currently visiting.
   *
   * For example if we're visiting `example.com`, the array could contain
   *  `https://solve.example.com`.
   */
  readonly firstPartyUrls?: string[];
  readonly revision?: string;
};

function getInitialState(firstPartyUrls: false | string[]): InitialState {
  return {
    tag: JwtStates.Initialisation,
    firstPartyUrls:
      firstPartyUrls === false || firstPartyUrls.length === 0
        ? false
        : firstPartyUrls
  };
}

function transitionToUrlLoadingState(
  state: JwtState,
  completionPromise: Promise<ApiConfiguration>
): UrlLoadingState {
  return {
    tag: JwtStates.LoadingUrl,
    completionPromise,
    firstPartyUrls: state.firstPartyUrls
  };
}
function transitionToValidState(
  state: JwtState,
  apiUrl: string,
  token: JwtFetchSuccess,
  isApiUrlFirstParty: boolean
): JwtValidState {
  return {
    tag: JwtStates.ValidUrlValidJWT,
    isApiUrlFirstParty,
    apiUrl,
    config: {
      apiUrl,
      bearerToken: token.jwt,
      isFirstPartyUrl: isApiUrlFirstParty
    },
    firstPartyUrls: state.firstPartyUrls,
    localExpiry: token.localExpiry
  };
}
function transitionToReloadingState(
  state: JwtValidState | JwtInvalidState,
  completionPromise: Promise<ApiConfiguration>
): JwtLoadingState {
  return {
    tag: JwtStates.ValidUrlLoadingJWT,
    completionPromise,
    apiUrl: state.apiUrl,
    firstPartyUrls: state.firstPartyUrls,
    isApiUrlFirstParty: state.isApiUrlFirstParty
  };
}

function transitionToInvalidJwtState(
  state: JwtLoadingState | JwtValidState
): JwtInvalidState {
  return {
    tag: JwtStates.ValidUrlInvalidJWT,
    apiUrl: state.apiUrl,
    firstPartyUrls: state.firstPartyUrls,
    isApiUrlFirstParty: state.isApiUrlFirstParty
  };
}
function transitionToInvalidJwtStateWithUrl(
  state: UrlLoadingState,
  apiUrl: string,
  isApiUrlFirstParty: boolean
): JwtInvalidState {
  return {
    tag: JwtStates.ValidUrlInvalidJWT,
    apiUrl,
    firstPartyUrls: state.firstPartyUrls,
    isApiUrlFirstParty
  };
}

function transitionToFallbackLoadingState(
  state: UrlLoadingState,
  fallbackApiUrl: string
): JwtLoadingState {
  return {
    tag: JwtStates.ValidUrlLoadingJWT,
    isApiUrlFirstParty: false,
    apiUrl: fallbackApiUrl,
    firstPartyUrls: false,
    completionPromise: state.completionPromise
  };
}

export default class JwtManager implements JwtManagerInterface {
  private readonly sentry: SentryTrackError;

  private readonly apiKey: string;
  private readonly solveStackApiUrl: string;
  private readonly revision?: string;
  private state: JwtState;
  private jwtInitPromise?: true | Promise<void> = undefined;

  constructor(sentry: SentryTrackError, config: SdkApiConfig) {
    this.sentry = sentry;
    this.revision = config.revision;
    this.apiKey = config.apiKey;

    // solveStackApiUrl acts as a default if there are no firstPartyUrls, or all
    //  firstPartyUrls fail.
    this.solveStackApiUrl = config.solveStackApiUrl; // e.g. https://prod-00.example.solvestack.net

    this.state = getInitialState(
      config.firstPartyUrls || [] // e.g. ["https://solve.example.com", ...]
    );
  }

  init(): void {
    // Only init the JWT once, otherwise we could end up with multiple sentry errors
    if (this.jwtInitPromise === undefined) {
      // On init we try to grab the JWT. This should make subsequent requests
      //  for the JWT information slightly faster.
      this.jwtInitPromise = (async () => {
        // Use an immediately invoked function to prevent any errors from the
        //  `getApiConfiguration` function from causing a unhandled promise
        //  rejection.
        try {
          await this.getApiConfiguration();
        } catch (e) {
          this.sentry(e, {
            tags: { web_sdk_origin: 'jwt-manager:init' }
          });
        } finally {
          this.jwtInitPromise = true;
        }
      })();
    }
  }

  /**
   * Returns the JWT from the first suitable API URL found.
   *
   * This function will first go through all the first party URLs that are
   *  present on the state and try to fetch a JWT from each one in turn. If the
   *  fetching succeeds, we use that URL; if the fetching fails with an
   *  Authentication error we throw that error; otherwise we keep going down the
   *  list until one succeeds.
   *
   * If we run out of first party URLs(or we had none to start with) we will
   *  call `_fetchJwt` with the solveStackApiUrl. This URL is assumed to always
   *  work.
   *
   * @param state The loading state when the function started processing
   * @returns The loaded API Configuration
   */
  private async _fetchUrlAndJwt(
    state: UrlLoadingState
  ): Promise<ApiConfiguration> {
    let latestState = state;
    while (
      latestState.firstPartyUrls !== false &&
      latestState.firstPartyUrls.length > 0
    ) {
      const [toCheck, ...rem] = latestState.firstPartyUrls;

      // Update the state so if fetching the JWT throws an error we don't retry
      //  the same URL forever.
      latestState = { ...latestState, firstPartyUrls: rem };
      this.state = latestState;

      const jwtResult = await fetchJwtFromEndpoint(
        toCheck,
        this.apiKey,
        this.revision
      );
      if (jwtResult.tag === JwtFetchResultType.Success) {
        this.state = transitionToValidState(
          latestState,
          toCheck,
          jwtResult,
          true
        );
        return this.state.config;
      } else if (jwtResult.tag === JwtFetchResultType.AuthenticationFailure) {
        // The first-party URL is alive and responding, but it most likely
        //  returned a 401. Move to an invalid JWT state with the current URL as
        //  the API URL.
        this.state = transitionToInvalidJwtStateWithUrl(
          latestState,
          toCheck,
          true
        );
        throw jwtResult.error;
      } else {
        // Connection error, so we just keep working down the list of URLs
        continue;
      }
    }

    // All URLs have been tried and failed, so we use the third-party URL
    this.state = transitionToFallbackLoadingState(
      latestState,
      this.solveStackApiUrl
    );
    return this._fetchJwt(this.state);
  }

  /**
   * Loads the API configuration from the already discovered API URL(in the state).
   *
   * This function can call `_fetchUrlAndJwt` if the current API URL is not working, and there are more first party URLs avaliable.
   *
   * @param state The current state(must be a 'JwtLoadingState')
   * @returns The loaded API configuration.
   */
  private async _fetchJwt(state: JwtLoadingState): Promise<ApiConfiguration> {
    const jwtResult = await fetchJwtFromEndpoint(
      state.apiUrl,
      this.apiKey,
      this.revision
    );
    if (jwtResult.tag === JwtFetchResultType.Success) {
      // We don't have a token saved, or the token we have is older than this one.
      // Overwrite the cached data
      this.state = transitionToValidState(
        state,
        state.apiUrl,
        jwtResult,
        state.isApiUrlFirstParty
      );
      return this.state.config;
    } else if (
      state.isApiUrlFirstParty &&
      jwtResult.tag === JwtFetchResultType.ConnectionFailure
    ) {
      // This URL is one of the first party URLs and we had a connection error.
      //  This means we should keep going down the first party URLs until we
      //  find one that works, or we exhaust all of them and use the default
      //  `solveStackApiUrl`. We call `_fetchUrlAndJwt` as it already implements
      //  this functionality.
      this.state = transitionToUrlLoadingState(state, state.completionPromise);
      return this._fetchUrlAndJwt(this.state);
    } else {
      this.state = transitionToInvalidJwtState(state);
      // Either:
      //  - This is a first-party URL, and there was an authenication error, which is always raised.
      //  - This is the `solveStackApiUrl`, in which case all errors are raised
      throw jwtResult.error;
    }
  }

  /**
   * Calls the given function in the next tick, and handles updating the state
   *  if the function throws an exception.
   *
   * @param promiseFn The function that will actually retrieve the API
   *                   configuration. This function will be called in the next
   *                   tick to allow it to access variables set in parent
   *                   scopes.
   * @returns The API configuration
   */
  private _wrapFetch(
    promiseFn: () => Promise<ApiConfiguration>
  ): Promise<ApiConfiguration> {
    const promise = Promise.resolve().then(promiseFn);
    // Catch errors from the promise to update the state to reflect the failure,
    //  but don't transform the original promise.
    promise.catch(e => {
      switch (this.state.tag) {
        case JwtStates.Initialisation:
        case JwtStates.LoadingUrl:
          this.state = getInitialState(this.state.firstPartyUrls);
          return null;
        case JwtStates.ValidUrlLoadingJWT:
          this.state = transitionToInvalidJwtState(this.state);
          return null;
        default:
          // State is either valid or invalid, in which case we preserve it.
          return null;
      }
    });
    return promise;
  }

  /**
   * This function loads the API configuration including the API URL and token.
   *
   * This function is save to call multiple times concurrently, all fetch
   * requests will use the same promise and will all return once the
   * configuration is loaded.
   *
   * The calling code can assume that the API configuration returned is valid at
   * the time of calling. Calling code can report an invalid token by passing
   * the token into `setUnauthenticated`. This will cause the next call to this
   * function to refetch the token from the server
   *
   * @returns The loaded API Configuration
   */
  public getApiConfiguration(): ApiConfiguration | Promise<ApiConfiguration> {
    if (
      this.state.tag === JwtStates.ValidUrlValidJWT &&
      Date.now() > this.state.localExpiry
    ) {
      // JWT has expired, so we mark the state as invalid before determining what steps are next.
      this.state = transitionToInvalidJwtState(this.state);
    }

    switch (this.state.tag) {
      case JwtStates.Initialisation:
        {
          const urlLoadingState = transitionToUrlLoadingState(
            this.state,
            this._wrapFetch(() => this._fetchUrlAndJwt(urlLoadingState))
          );
          this.state = urlLoadingState;
        }
        return this.state.completionPromise;

      case JwtStates.LoadingUrl:
      case JwtStates.ValidUrlLoadingJWT:
        // A load is in progress, return the stored promise which will resolve or
        //  reject once the loading is completed.
        return this.state.completionPromise;

      case JwtStates.ValidUrlValidJWT:
        return this.state.config;

      default:
        {
          // Either previous loads failed, or the loaded token has expired.
          const reloadingState = transitionToReloadingState(
            this.state,
            this._wrapFetch(() => this._fetchJwt(reloadingState))
          );
          this.state = reloadingState;
        }
        return this.state.completionPromise;
    }
  }

  /**
   *
   * @returns True if there are first-party URLs that can be used.
   */
  public hasFirstPartyUrls(): boolean {
    if (
      this.state.tag === JwtStates.Initialisation ||
      this.state.tag === JwtStates.LoadingUrl
    ) {
      // We set `firstPartyUrls` to false when there are no more first party
      //  URLs to check.
      // Note that `firstPartyUrls` can be an empty list if we are currently
      //  examining the final first party URL for connectivity. In this
      //  situation we want to return `true` as that URL could actually be valid
      //  and thus used.
      return this.state.firstPartyUrls !== false;
    } else {
      return this.state.isApiUrlFirstParty;
    }
  }

  /**
   * Clear the current token if it matches the given token. This will force the
   *  next call to `getApiConfiguration` to fetch a new token from the server.
   *
   * @param maybeToken The token that was used when the server returned an
   * unauthenticated error.
   */
  public setUnauthenticated(maybeToken?: string): void {
    // This function only works if the JWT has loaded, otherwise it has no effect
    if (this.state.tag === JwtStates.ValidUrlValidJWT) {
      const token = maybeToken || this.state.config.bearerToken;
      if (this.state.config.bearerToken === token) {
        // Force a re-request of the token.
        // TODO: we should probably limit the number of times this is allowed to happen per page load.
        this.state = transitionToInvalidJwtState(this.state);
      }
    }
  }
}
