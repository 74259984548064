import { MetaTag, MetaValues } from './page';
import Shopify from './shopify';

const extractStoreFromMetaTags = (metaTags: MetaTag[]): string | null => {
  if (
    metaTags === null ||
    metaTags.every((metaTag: MetaTag | null) => metaTag === null)
  ) {
    return null;
  }
  const storeTags = metaTags.filter(
    (metaTag: MetaTag) => metaTag.name === 'solve:store'
  );

  if (storeTags.length === 0) {
    return null;
  } else {
    return storeTags[0].content;
  }
};

export const getStoreFromPage = (
  metaTags: MetaValues[],
  shopify: Shopify
): string | undefined => {
  const metaTagStore = extractStoreFromMetaTags(metaTags);

  if (metaTagStore) {
    return metaTagStore;
  } else if (shopify.isShopifyPage()) {
    return shopify.store();
  } else {
    return undefined;
  }
};
