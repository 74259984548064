/** Before anything else, sort out polyfills :) */
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';
import 'url-polyfill';

import { GIT_REVISION } from './config';
import { SessionStorage } from './storage';
import {
  getCurrentPageDetails,
  getSourceAttributionDetails,
  getMetaTagDetails,
  getNonAttributionQueryParamDetails,
  PageDetails
} from './page';
import { getProfileIfExists } from './profile';
import { getCurrentChannel } from './channel';
import { track } from './sentry';
import {
  SolveLifecycle,
  SolveLifecycleHandler,
  addLifecycleHookHandler,
  removeLifecycleHookHandler,
  fireLifecycleHookAlteringEvent,
  fireLifecycleHookNonAlteringEvent
} from './lifecycleHandlers';
import Shopify, { createOnCartCreationPromise } from './shopify';
import PublicProfile, { PublicProfileResponse } from './publicProfile';
import { buildSolveApiFromSolveConfigObject } from './newRequest';
import { getStoreFromPage } from './storeResolution';
import { Sessions } from './storage/sessions';
import { retrieveSdkParamsFromScriptUrl } from './urlConfig';
import {
  createShopConfigFetcherFromSdkConfig,
  ShopConfigFetcher,
  SolveConfigShopify
} from './shopify/shopConfig';
import { SentryTrackError } from './newRequest/interfaces';
import { LINKING_ID_NOT_GENERATED } from './storage/linkingId';
import { PublicCart } from './publicCart';

/*
 * Solve Web SDK. Expects the global _solve object to exist (from the user loaded script)
 *
 * Once initialised with the client given config details it exposes a global object `solve`
 * which a developer can use to fire off custom events. On load it fires off a page view event
 * (unless disableAutomaticPageView is set to `true`).
 *
 */

export interface RawSolveEvent {
  type: string;
  payload: Record<string, unknown>;
  eventTime?: string;
}
export interface SolveEvent extends RawSolveEvent {
  sessions: Sessions;
  linking_id?: string;
  order_id?: string;
  cart_id?: string;
  product_id?: string;
  store?: string;
}

export type SolveReadyFunction = () => void;

export type SolveConfig = {
  shopify?: SolveConfigShopify;
} & Record<string, any>;

/**
 * Solve init params
 */
export interface _Solve {
  apiKey: string;
  apiUrl: string;
  /**
   * A map of domain-suffixes to their first party API URLs
   *
   * For example if you have `stack.solve.io` and `stack.solvedata.io`, you would set this object to
   * ```
   * {
   *   'solve.io': ['stack.solve.io'],
   *   'solvedata.io': ['stack.solvedata.io']
   * }
   * ```
   */
  domains?: Record<string, string | string[]>;
  config?: SolveConfig;
  disableAutomaticPageView?: boolean;
  excludeMetaTags?: string[];
  excludeQueryParams?: string[];
  __internalRefererSessionOverride?: boolean;
  ready?: SolveReadyFunction[];
}

// The type of the main solve object
export interface SolveWindow {
  // This key is used in `sdkEntry.ts` to detect if another SDK is on the page.
  //  Please don't delete it without modifying that code first.
  revision?: string;
  customEvent: (type: string, data?: Record<string, unknown>) => Promise<void>;
  pageview: (data?: Record<string, unknown>) => Promise<void>;
  identify: (data?: Record<string, unknown>) => Promise<void>;
  search: (
    search_term: string,
    attributes?: Record<string, unknown>
  ) => Promise<void>;
  getProfile: (
    callback?: (profile: PublicProfile) => unknown
  ) => Promise<PublicProfile>;
  getCart: (
    id: string,
    provider: string,
    callback?: (cart: PublicCart | null) => unknown
  ) => Promise<PublicCart | null>;
  createOrUpdateCart: (
    input: Record<string, any>,
    callback?: (response: PublicCart) => unknown
  ) => Promise<PublicCart>;
  convertCart: (
    id: string,
    provider: string,
    orderId?: string,
    callback?: (response: PublicCart) => unknown
  ) => Promise<PublicCart>;
  destroy: () => void;
  addLifecycleHookHandler: (
    lifecycleHookName: SolveLifecycle,
    handler: SolveLifecycleHandler
  ) => number;
  removeLifecycleHookHandler: (
    lifecycleHookName: SolveLifecycle,
    handlerKey: number
  ) => void;
  ready?: (callback: SolveReadyFunction) => void;
  _logError: (
    error: Error | string,
    params: { tags?: Record<string, any>; extra?: Record<string, any> }
  ) => void;
}

const logError = (
  _solve: _Solve,
  error: Error,
  extra: Record<string, any>
): void => {
  track(error, extra);
};

/**
 * Initialises the Solve SDK and exposes a `solve` object on the window for
 * developers to hook into events.
 *
 * @param _solve Object placed on the page by the SDK init script
 */
export default function createSdk(_solve?: _Solve) {
  return createSdkWithServices(_solve);
}

/**
 * We don't want to expose being able to pass in custom services so we hide
 * this function internally
 */
export function createSdkWithServices(
  configArgument?: _Solve,
  // Services that can be mocked (we can add more here as we need to)
  services: {
    shopifyOnCartCreationPromise?: any;
    storage?: SessionStorage;
  } = {}
): SolveWindow | undefined {
  let _solve: _Solve;
  let shopifyConfigFetcher: ShopConfigFetcher;
  if (configArgument === undefined) {
    const { solveConfig, shopify } = retrieveSdkParamsFromScriptUrl();
    _solve = solveConfig;
    shopifyConfigFetcher = shopify;
  } else {
    _solve = configArgument;
    shopifyConfigFetcher = createShopConfigFetcherFromSdkConfig(_solve);
  }

  const {
    shopifyOnCartCreationPromise = createOnCartCreationPromise,
    storage = new SessionStorage(!!_solve.__internalRefererSessionOverride)
  } = services;

  const sentryTrack: SentryTrackError = (error, extra) =>
    logError(_solve, error, extra);
  const shopify = new Shopify(shopifyConfigFetcher, {
    onCartCreation: shopifyOnCartCreationPromise,
    sentryTrack
  });

  const revision = GIT_REVISION();
  const api = buildSolveApiFromSolveConfigObject(_solve, {
    revision,
    sessions: storage,
    sentryTrack
  });

  if (!_solve) {
    logError(_solve, new Error('Global `_solve` object does not exist'), {
      tags: { web_sdk_origin: 'missing _solve' }
    });
    return undefined;
  }

  // this object is used to tract the
  let lastPageInfo: {
    detail: PageDetails;
    time: Date;
  };

  api.init();

  async function sendEvent(event: RawSolveEvent) {
    try {
      // Generate the end payload to send to the GraphQL server. This includes the payload
      // that the client developer has sent through and also all the automatic `solve_metadata`
      // that we always capture
      const metaTags = getMetaTagDetails(_solve.excludeMetaTags);

      // Get the current key for the user
      const { reason: session_reason, value: sessions } = storage.getSessions({
        cacheReason: false
      });

      const payload = {
        ...event.payload,
        solve_metadata: {
          ...getCurrentPageDetails(),
          ...getCurrentChannel(),
          attribution: getSourceAttributionDetails(),
          meta_tags: metaTags,
          query_params: getNonAttributionQueryParamDetails(
            _solve.excludeQueryParams
          ),
          session_regeneration_reason: session_reason
        }
      } as any;

      let linking_id;
      if (!api.hasFirstPartyUrls()) {
        // We aren't going to use a First Party URL so we can just generate the
        //  linking ID now
        const lidAndReason = storage.getLinkingId({
          cacheReason: false,
          generateIfNotPresent: true
        });
        if (lidAndReason !== LINKING_ID_NOT_GENERATED) {
          linking_id = lidAndReason.value;
          payload.solve_metadata.linking_id_regeneration_reason =
            lidAndReason.reason;
        }
      } else {
        // We could use a First Party URL, so we shouldn't generate a Linking ID
        //  on the client, but instead rely on the server to either fill in one
        //  from a Cookie, or generate one.
        const lidAndReason = storage.getLinkingId({
          cacheReason: false,
          generateIfNotPresent: false
        });
        if (lidAndReason !== LINKING_ID_NOT_GENERATED) {
          linking_id = lidAndReason.value;
          payload.solve_metadata.linking_id_regeneration_reason =
            lidAndReason.reason;
        }
      }

      const profile = getProfileIfExists();
      if (profile !== null) {
        // There is come profile info available. Store it in the correct place
        // for identify resolution to pick it up
        payload.profile = profile;
      }

      // we check if a page is shopify or there is a 'solve:store' meta tag to find the store
      const store = getStoreFromPage(metaTags, shopify);

      // Fire off the lifecycle handlers for before we send the event
      const eventToSend = await fireLifecycleHookAlteringEvent('beforesend', {
        type: event.type,
        sessions: sessions || {},
        linking_id,
        payload,
        eventTime: new Date().toISOString(),
        store
      });

      await api.queueEvent(eventToSend);

      // Fire off the lifecycle handlers for after we've sent the event
      await fireLifecycleHookNonAlteringEvent('aftersend', eventToSend);
    } catch (err) {
      logError(_solve, err, { tags: { web_sdk_origin: 'sendEvent' } });
    }
  }

  function initSDK() {
    const windowSolve: SolveWindow = {
      revision,
      customEvent: (type, data) => {
        const cleaned_type = type
          .replace(/\./g, '_')
          .replace(/([A-Z])/g, '_$1')
          .toLowerCase();
        return sendEvent({
          type: `custom_${cleaned_type}`,
          payload: data || {}
        });
      },
      pageview: data => {
        const currentPageInfo = {
          detail: getCurrentPageDetails(),
          time: new Date()
        };
        // if the previous page is the same as the current page
        // and the time between pageviews is <= 10 seconds
        // then don't send a pageview event
        if (lastPageInfo !== undefined) {
          if (
            JSON.stringify(currentPageInfo.detail) ===
            JSON.stringify(lastPageInfo.detail)
          ) {
            // if NOW() - 10 sec >= the last page with same url
            if (
              currentPageInfo.time.getTime() - lastPageInfo.time.getTime() <=
              10000
            ) {
              console.debug('Page unchanged, not sending event');
              return Promise.resolve();
            }
          }
        }

        lastPageInfo = currentPageInfo;
        return sendEvent({
          type: 'pageview',
          payload: data || {}
        });
      },
      identify: data => {
        return sendEvent({
          type: 'identify',
          payload: data || {}
        });
      },
      search: (search_term, attributes = {}) => {
        return sendEvent({
          type: 'search',
          payload: {
            search_term,
            attributes
          }
        });
      },
      destroy: () => {
        return storage.destroy();
      },
      addLifecycleHookHandler: (lifecycleHookName, handler) => {
        return addLifecycleHookHandler(lifecycleHookName, handler);
      },
      removeLifecycleHookHandler: (lifecycleHookName, handlerKey) => {
        return removeLifecycleHookHandler(lifecycleHookName, handlerKey);
      },
      getProfile: callback => {
        const defaultProfile = { exists: false };
        const shouldGenerateLinkingId = !api.hasFirstPartyUrls();

        const sessions = storage.getSessions({ cacheReason: true }).value;
        const lidAndReason = storage.getLinkingId({
          cacheReason: true,
          generateIfNotPresent: shouldGenerateLinkingId
        });

        let publicProfile: Promise<PublicProfileResponse>;
        if (
          sessions ||
          lidAndReason === LINKING_ID_NOT_GENERATED ||
          lidAndReason.value
        ) {
          // We have a Session ID, a Linking ID, or we are expecting the server to generate a Linking ID.
          publicProfile = api.queryPublicProfile({
            sessions,
            linking_id:
              lidAndReason === LINKING_ID_NOT_GENERATED
                ? LINKING_ID_NOT_GENERATED
                : lidAndReason.value
          });
        } else {
          // We are missing a Session ID *and* a Linking ID(usually caused by a non-working local-storage)
          publicProfile = new Promise(resolve => resolve(defaultProfile));
        }
        const profilePromise = publicProfile
          .then(
            profile => profile || defaultProfile,
            _err => {
              // TODO: Report the error to sentry.
              return defaultProfile;
            }
          )
          .then(profile => new PublicProfile(profile));

        if (callback) {
          profilePromise.then(callback);
        }
        return profilePromise;
      },
      getCart(id, provider, callback?) {
        const cartPromise = api.queryPublicCart(id, provider).then(res => {
          if (res) return new PublicCart(res);
          return null;
        });

        if (callback) {
          cartPromise.then(callback);
        }

        return cartPromise;
      },
      createOrUpdateCart(cartInput, callback?) {
        const sessions = storage.getSessions({ cacheReason: true }).value;

        // TODO: work out better session_id extraction
        const cartPromise = api
          .createOrUpdatePublicCart(cartInput, {
            session_id: sessions?.['solve.sdk']
          })
          .then(res => {
            if (!res) throw new Error('Returned cart was null');
            return new PublicCart(res);
          });

        if (callback) {
          cartPromise.then(callback);
        }

        return cartPromise;
      },
      convertCart(id, provider, orderId?, callback?) {
        const sessions = storage.getSessions({ cacheReason: true }).value;

        // TODO: work out better session_id extraction
        const cartPromise = api
          .convertPublicCart(id, provider, orderId, {
            session_id: sessions?.['solve.sdk']
          })
          .then(res => {
            if (!res) throw new Error('Returned cart was null');
            return new PublicCart(res);
          });

        if (callback) {
          cartPromise.then(callback);
        }

        return cartPromise;
      },
      _logError: (error, params) => {
        error = typeof error === 'string' ? new Error(error) : error;
        logError(_solve, error, params);
      }
    };

    shopify.registerEventHandlers(windowSolve);

    if (_solve.ready !== undefined) {
      // Using a snippet with a `.ready` setup. Call the ready functions
      //  before the pageview is sent, but after the real `window.solve`
      //  is set.
      const ready = (callback: SolveReadyFunction) => {
        try {
          callback();
        } catch (e) {
          console.error(e);
        }
      };
      windowSolve.ready = ready;
      // Use `setTimeout` to ensure the `window.solve` object is the real
      //  SDK when this runs.
      setTimeout(() => {
        _solve.ready?.forEach(callback => {
          ready(callback);
        });
      });
    }

    // Script has loaded for the first time. Fire off a page view event unless the user has
    // disabled it
    if (!_solve.disableAutomaticPageView) {
      if (document.readyState === 'loading') {
        window.addEventListener('DOMContentLoaded', () => {
          windowSolve.pageview();
        });
      } else {
        // Give other scripts on the page a chance to load.
        // This is what jquery does
        // https://github.com/jquery/jquery/blob/3.6.0/src/core/ready.js#L75
        setTimeout(() => windowSolve.pageview());
      }
    }

    // Will add the `slv_rt` parameter if required.
    storage.setupSessionParameter();

    return windowSolve;
  }

  return initSDK();
}
