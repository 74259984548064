/**
 * This grabs any known profile information from known sources and adds them to
 * a return object.
 */

import { getAllQueryParams } from './page';
import { decodeURIComponentSafe } from './helpers';

// Mapping of supported URL params and the property name that goes against the profile
const urlCommonKnownParams = {
  slv_e: 'email',
  slv_m: 'email_md5',
  slv_s: 'email_sha256',
  slv_be: 'email',
  email: 'email'
};

export const getProfileIfExists = (): Record<string, unknown> | null => {
  const profile: Record<string, unknown> = {};
  let hasProfileProperty = false;
  const queryParams = getAllQueryParams();
  // For each known parameter in our dictionary, attempt to get any matching url parameter.
  // If any exist, add it to the `profile` object with the correct property name
  Object.keys(urlCommonKnownParams).forEach(param => {
    const value = queryParams[param];
    if (value) {
      const property = urlCommonKnownParams[param];
      if (param === 'slv_be') {
        // Decode the String
        profile[property] = atob(
          decodeURIComponentSafe(value, { ignorePlusChars: true })
        );
      } else {
        try {
          profile[property] = decodeURIComponentSafe(value, {
            ignorePlusChars: true
          });
        } catch {
          console.error(`Failed with error when tried to decode ${value}`);
        }
      }
      // Set this flag so we know whether to return `null` or the profile object
      hasProfileProperty = true;
    }
  });

  return hasProfileProperty ? profile : null;
};
