// This file is the WebPack build entrypoint.

// Only load the SDK if there wasn't one on the page before. We can't just use
//  `window.solve` as modern snippets set `window.solve.ready` to a function.
if (!(window as any).solve?.revision) {
  // Need to use require/module.exports so the created SDK object is placed
  //  in `window.solve` rather than at `window.solve.default`
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const sdk = require('./sdk').default;

  (window as any).solve = sdk((window as any)._solve);
}
