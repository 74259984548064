/**
 * We need to limit the size of the SDK, and the Adler-32 library is not
 * configured to allow Webpack to remove unused exports. This means every
 * function in the library is included even though most are not needed.
 *
 * The changes made were:
 *  - Define variables with `let` or `const` to appease the linter.
 *  - Removed the `seed` argument, as we don't need to use it.
 */
// Adapted from `adler-32` 1.3.0. Seed option removed.
export function adler32Bstr(bstr: string): number {
  let a = 1;
  let b = 0;
  const L = bstr.length;
  let M = 0;
  for (let i = 0; i < L; ) {
    M = Math.min(L - i, 2654) + i;
    for (; i < M; i++) {
      a += bstr.charCodeAt(i) & 0xff;
      b += a;
    }
    a = 15 * (a >>> 16) + (a & 65535);
    b = 15 * (b >>> 16) + (b & 65535);
  }
  return (b % 65521 << 16) | a % 65521;
}
