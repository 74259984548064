interface channel {
  channel: string;
}
/**
 * Gets the current event channel. Currently hard coded to `browser`
 */
export function getCurrentChannel(): channel {
  return {
    channel: 'browser'
  };
}
