import 'es7-object-polyfill';
import { SolveEvent } from './sdk';

let nextHandlerKey = 1;

export type SolveLifecycle = 'beforesend' | 'aftersend';
export interface SolveLifecycleHandler {
  // Note: As this is JavaScript, users can register handlers and which return
  // something bizzare like undefined
  (event: SolveEvent): Promise<SolveEvent> | SolveEvent | unknown;
}

export const lifecycleHandlers: Record<
  SolveLifecycle,
  Record<string, SolveLifecycleHandler>
> = {
  aftersend: {},
  beforesend: {}
};

export const addLifecycleHookHandler: (
  lifecycleHookName: SolveLifecycle,
  handler: SolveLifecycleHandler
) => number = (lifecycleHookName, handler) => {
  const key = nextHandlerKey;
  lifecycleHandlers[lifecycleHookName][key] = handler;
  nextHandlerKey = nextHandlerKey + 1;

  return key;
};

export const removeLifecycleHookHandler: (
  lifecycleHookName: SolveLifecycle,
  handlerKey: number
) => void = (lifecycleHookName, handlerKey) => {
  if (!lifecycleHandlers[lifecycleHookName]) {
    throw new Error(
      `Solve lifecycle hook name of ${lifecycleHookName} does not exist`
    );
  }
  delete lifecycleHandlers[lifecycleHookName][handlerKey];
};

export const fireLifecycleHookAlteringEvent: (
  lifecycleHookName: SolveLifecycle,
  initialEvent: SolveEvent
) => Promise<SolveEvent> = async (lifecycleHookName, initialEvent) => {
  const handlers = lifecycleHandlers[lifecycleHookName];
  let event = initialEvent;
  for (const handler of Object.values(handlers)) {
    try {
      // Note that handlerResult may not actually be a promise. In that case,
      // the `await` does nothing and returns `handlerResult`.
      const handlerResult = await handler(event);
      if (isEventObject(handlerResult)) {
        event = handlerResult as SolveEvent;
      } else {
        // Do nothing, keep `event` the same value in case the handler
        // modified it
      }
    } catch (err) {
      console.error(
        `Error running ${lifecycleHookName} lifecycle handler`,
        err
      );
    }
  }
  return event;
};

export const fireLifecycleHookNonAlteringEvent: (
  lifecycleHookName: SolveLifecycle,
  initialEvent: SolveEvent
) => Promise<void> = async (lifecycleHookName, event) => {
  const handlers = lifecycleHandlers[lifecycleHookName];
  for (const handler of Object.values(handlers)) {
    try {
      await handler({ ...event });
    } catch (err) {
      console.error(
        `Error running ${lifecycleHookName} lifecycle handler`,
        err
      );
    }
  }
};

const isEventObject = (event: any) =>
  typeof event === 'object' &&
  !Array.isArray(event) &&
  typeof event.type === 'string';
