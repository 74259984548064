interface URIDecodingOptions {
  ignorePlusChars?: boolean;
}

/**
 * Returns the input value unchanged if input is empty
 * replaces every % NOT followed by a two-digit (hex) number with %25
 * returns the decoded string.
 */
export const decodeURIComponentSafe = (
  value: string,
  options: URIDecodingOptions = {}
): string => {
  if (!value) {
    return value;
  }
  return decodeURIComponent(
    options.ignorePlusChars
      ? value.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')
      : value.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25').replace(/\+/g, '%20')
  );
};
