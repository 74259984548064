import { SolveEvent } from '../sdk';
import { SessionInterface } from '../storage';
import { LINKING_ID_NOT_GENERATED } from '../storage/linkingId';
import {
  EventSenderInterface,
  GraphQlApiInterface,
  EventQueueInterface,
  SentryTrackError
} from './interfaces';

export const SEND_EVENT_MUTATION =
  'mutation ($input: EventInput!) { queueEvent(input: $input) { eventTime } }';

export default class EventSender implements EventSenderInterface {
  private readonly api: GraphQlApiInterface;
  private readonly storage: EventQueueInterface;
  private readonly sessions: SessionInterface;
  private readonly sentry: SentryTrackError;

  private sendEventTimeout?: number = undefined;
  private readonly eventPromiseResolver: Record<string, (_: any) => void> = {};

  constructor(
    api: GraphQlApiInterface,
    storage: EventQueueInterface,
    sessions: SessionInterface,
    sentry: SentryTrackError
  ) {
    this.api = api;
    this.storage = storage;
    this.sentry = sentry;
    this.sessions = sessions;
  }

  public init(): void {
    this.api.init();
    // Initialising the storage could pull events from localstorage
    // So we may need to schedule some event sending.
    this.storage.init();
    if (this.storage.hasUnprocessedEvents()) {
      // Immediately schedule more events to be processed.
      this.scheduleSendEvents(0);
    }
  }

  public queueEvent(event: SolveEvent): Promise<void> {
    const eventSdkId = this.storage.addEvent(event);
    const eventSentPromise: Promise<void> = new Promise(
      resolve => (this.eventPromiseResolver[eventSdkId] = resolve)
    );

    this.scheduleSendEvents();
    return eventSentPromise;
  }

  scheduleSendEvents(delay = 0): void {
    if (this.sendEventTimeout) {
      // Nothing to do, there is a timeout already running
    } else {
      const timeout = (this.sendEventTimeout = window.setTimeout(() => {
        this.sendStoredEvents()
          .then(
            () => {
              // Sending finished, clear the timeout.
              // We'll reschedule the sending if we need to in the then/catch handlers.
              if (this.sendEventTimeout === timeout) {
                this.sendEventTimeout = undefined;
              }

              if (this.storage.hasUnprocessedEvents()) {
                // Immediately schedule more events to be processed.
                this.scheduleSendEvents(0);
              }
            },
            err => {
              // Sending finished, clear the timeout.
              // We'll reschedule the sending if we need to in the then/catch handlers.
              if (this.sendEventTimeout === timeout) {
                this.sendEventTimeout = undefined;
              }

              console.error(`Unable to send an event due to this error:`, {
                err,
                delay
              });
              // Send failed for some reason, back-off
              const newDelay = delay <= 0 ? 4000 : delay * 2;
              if (delay > 60000) {
                // We're not having any success. stop retrying.
                return Promise.reject(err);
              }
              if (this.storage.hasUnprocessedEvents()) {
                // Schedule more events to be processed.
                this.scheduleSendEvents(newDelay);
              }
              return Promise.resolve();
            }
          )
          .catch(err => {
            this.sentry(err, {
              tags: { web_sdk_origin: 'event-sender:delay-expired' },
              extra: {
                hasUnprocessedEvents: this.storage.hasUnprocessedEvents()
              }
            });
          });
      }, delay));
    }
  }

  async sendStoredEvents(): Promise<void> {
    // Loop until we run out of events to process.
    while (true) {
      const [id, event] = this.storage.getEventForProcessing();
      if (!id || !event) {
        // No more events to process
        return;
      }
      try {
        let forceServerSessions = false;
        if (!event.linking_id) {
          // We don't have a Linking ID, which likely means we are expecting the
          // server to generate one. Before we send the event we should check
          // for a local Linking ID first.
          const shouldGenerateLinkingId = !this.api.hasFirstPartyUrls();

          const lidAndReason = this.sessions.getLinkingId({
            cacheReason: false,
            generateIfNotPresent: shouldGenerateLinkingId
          });
          if (lidAndReason !== LINKING_ID_NOT_GENERATED) {
            // This path means:
            //  - Local storage is not working(which will be indiciated in the
            //     reason)
            //  - There is a non-expired Linking ID in local storage
            //  - The referer contained a valid Linking ID.
            //  - There is a 'destroyed' flag in local storage, which makes
            //     the SDK generate a new Linking ID to correctly overwrite
            //     the one set on the server.
            event.linking_id = lidAndReason.value;
            if (event.payload.solve_metadata) {
              (
                event.payload.solve_metadata as any
              ).linking_id_regeneration_reason = lidAndReason.reason;
            }
          } else {
            // This path means that local storage is working, and it does *not*
            //  contain a Linking ID. In this situation we expect the server to
            //  generate one.
            if (event.payload.solve_metadata) {
              (
                event.payload.solve_metadata as any
              ).linking_id_regeneration_reason = 'expecting_server_side_id';
            }
            // We must tell the server that it needs to fill in a Linking ID
            //  because we did not generate one.
            forceServerSessions = true;
          }
        }

        const body = JSON.stringify({
          query: SEND_EVENT_MUTATION,
          variables: {
            input: {
              type: event.type,
              event_time: event.eventTime,
              linking_id: event.linking_id,
              cart_id: event.cart_id,
              order_id: event.order_id,
              product_id: event.product_id,
              store: event.store,
              sessions: JSON.stringify(event.sessions),
              payload: JSON.stringify(event.payload)
            }
          }
        });
        await this.api.sendRequest(body, {
          forceServerSessions
        });

        this.storage.deleteEvent(id);

        if (this.eventPromiseResolver[id]) {
          // This function is the `resolve` function of a promise returned by `queueEvent`.
          // This can be null if the event in question has been loaded from localStorage.
          this.eventPromiseResolver[id](null);
        }
      } catch (e) {
        this.storage.markEventUnprocessed(id, e || true);
        // Must return otherwise this function will loop forever and cause a memory leak
        return Promise.reject(e);
      } finally {
        // Definitely clear the processing flag.
        this.storage.markEventUnprocessed(id);
      }
    }
  }
}
