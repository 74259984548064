/* global msCrypto */

import { v4 } from 'uuid';

// Adapted from https://github.com/uuidjs/uuid/blob/v3.4.0/lib/rng-browser.js
const getRandomValues =
  (typeof crypto !== 'undefined' &&
    crypto.getRandomValues &&
    crypto.getRandomValues.bind(crypto)) ||
  (typeof msCrypto !== 'undefined' &&
    typeof msCrypto.getRandomValues === 'function' &&
    msCrypto.getRandomValues.bind(msCrypto));

function rng() {
  let random;
  if (getRandomValues) {
    try {
      random = getRandomValues(new Uint8Array(16));
    } catch (_) {}
  }
  if (random) {
    return random;
  } else {
    const rnds = new Array(16);
    for (let i = 0; i < 16; i++) {
      rnds[i] = (Math.random() * 0x100) & 0xff;
    }

    return rnds;
  }
}

export default function uuid(): string {
  return v4({ rng });
}
