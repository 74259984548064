import { adler32Bstr as alder32 } from '../../../sdk/storage/referrer/adler32';

// Expires in 5 minutes.
export const REFERRER_EXPIRY_MS = 5 * 60 * 1000;

export type SessionInformation = { sessionId: string; linkingId: string };
export type ExtractedSessionInformation =
  | { valid: false }
  | ({ valid: true } & SessionInformation);

export function generateParameter({
  sessionId,
  linkingId
}: SessionInformation): string {
  if (sessionId.indexOf('~') !== -1 || linkingId.indexOf('~') !== -1)
    throw new Error('Session ID and Linking ID must not contain `~`.');

  const expiry = new Date().getTime() + REFERRER_EXPIRY_MS;

  const param = `${expiry.toString(32)}~${sessionId}~${linkingId}`;
  const checksum = alder32(param);
  return `v1~${param}~${checksum.toString(32)}`;
}

export function extractSession(parameter: string): ExtractedSessionInformation {
  if (parameter.indexOf('v1~') === 0) {
    return extractSessionV1(parameter.substring(3));
  } else {
    return { valid: false };
  }
}

function extractSessionV1(
  paramWithoutVersion: string
): ExtractedSessionInformation {
  // Grab the checksum and check it's value
  const checksumIndex = paramWithoutVersion.lastIndexOf('~');
  if (checksumIndex === -1) return { valid: false };

  const checksumValue = paramWithoutVersion.substring(checksumIndex + 1);
  const checksumInput = paramWithoutVersion.substring(0, checksumIndex);
  const expectedChecksum = alder32(checksumInput).toString(32);
  if (checksumValue !== expectedChecksum) return { valid: false };

  // Checksum is valid, now check expiry.
  const [expiryStr, sessionId, linkingId] = checksumInput.split('~', 3);
  if (
    expiryStr === undefined ||
    sessionId === undefined ||
    linkingId === undefined
  )
    return { valid: false };

  const expiryValue = parseInt(expiryStr, 32);
  if (!isFinite(expiryValue)) {
    // expiryValue is likely NaN due to parseInt failing.
    return { valid: false };
  }
  if (new Date().valueOf() < expiryValue) {
    // Not Expired
    return { valid: true, sessionId, linkingId };
  } else {
    return { valid: false };
  }
}
