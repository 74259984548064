/**
 * Gets details about the page the user is on
 */

import { decodeURIComponentSafe } from './helpers';

export interface PageDetails {
  title: string;
  url: string;
  full_url: string;
  user_agent: string;
  referrer: string;
}

export interface MetaValues {
  name: string;
  content: string;
}

export interface MetaTag {
  property?: string;
  name?: string;
  content: string;
}

// UTM codes etc that we assign as attribution values for `solve_metadata`
const attributionQueryParams = [
  'slv_campaign',
  'slv_medium',
  'slv_term',
  'slv_content',
  'slv_source',
  'slv_ac',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'gclid',
  'fbclid',
  'adset_id',
  'adset_name',
  'ad_id',
  'ad_name',
  'campaign_id',
  'fb_ad_id'
];

/**
 * Returns the url, title, and the user-agent from the current browser session,
 */
export function getCurrentPageDetails(): PageDetails {
  return {
    url: `${document.location.protocol}//${document.location.host}${document.location.pathname}`,
    full_url: `${document.location}`,
    title: document.title,
    user_agent: window.navigator.userAgent,
    referrer: document.referrer
  };
}

/**
 * Helper function to grab all param values. Would have used `URLSearchParams`
 * except that doesn't support IE at all so this is what it is.
 */
export function getAllQueryParams(): Record<string, string> {
  let match;
  const search = /([^&=]+)=?([^&]*)/g;

  const queryParams = {};
  while ((match = search.exec(window.location.search.substring(1)))) {
    queryParams[match[1]] = match[2];
  }

  return queryParams;
}

/**
 * Will return an object containing key value pairs corresponding to the common g/a UTM codes
 * if they exist in the URL. If none exist, this will return an empty object.
 */
export function getSourceAttributionDetails(): Record<string, string> {
  const attributions = {};
  const queryParams = getAllQueryParams();
  attributionQueryParams.forEach(code => {
    const value = queryParams[code];
    if (value) {
      try {
        attributions[code] = decodeURIComponentSafe(value);
      } catch {
        console.error(`Failed with error when tried to decode ${value}`);
      }
    }
  });

  return attributions;
}

/**
 * As opposed to the `getSourceAttributionDetails` function above, this will return all query
 * params that are not explicitly for attribution use. It also takes an optional array of strings
 * of query params to exclude
 */
export function getNonAttributionQueryParamDetails(
  excludeQueryParams?: string[]
): Record<string, string> {
  const queryParams = getAllQueryParams();

  attributionQueryParams.forEach(code => {
    if (queryParams[code]) {
      delete queryParams[code];
    }
  });

  if (excludeQueryParams && excludeQueryParams.length) {
    excludeQueryParams.forEach(code => {
      if (queryParams[code]) {
        delete queryParams[code];
      }
    });
  }

  // decode rest of query params
  Object.entries(queryParams).forEach(([key, value]) => {
    try {
      queryParams[key] = decodeURIComponentSafe(value);
    } catch {
      console.error(`Failed with error when tried to decode ${value}`);
    }
  });

  return queryParams;
}

/**
 * Meta tags are weird. Sometimes it seems like the name of the meta tag is in the `name` attribute
 * on the node. Other times it looks like it's under `property`. This just does a simple piece
 * of normalisation. If we can't track the name and value of the meta tag then don't return
 * anything.
 */
export function getPropertyAndContentFromMetaTag(
  metaTag: HTMLMetaElement
): MetaValues {
  const name = metaTag.getAttribute('name') || metaTag.getAttribute('property');
  const content = metaTag.getAttribute('content');
  if (!name || !content) {
    return { name: 'INVALID_TAG', content: '' };
  }

  return {
    name,
    content
  };
}

/**
 * Will return an array containing meta tag objects
 */
export function getMetaTagDetails(excludeMetaTags?: string[]): MetaValues[] {
  const metaTagNodeList = document.querySelectorAll('meta');
  // `querySelectorAll` returns a Nodelist. Need to turn it to an array to filter it.
  const metaTags = Array.prototype.slice.call(metaTagNodeList, 0);
  const metaTagsToExclude =
    excludeMetaTags && excludeMetaTags.length ? excludeMetaTags : [];

  // Get the name/content values, filter out any null excluded values, and mush into a single object
  return metaTags
    .map(getPropertyAndContentFromMetaTag)
    .filter(
      (t: MetaValues) =>
        t.name !== 'INVALID_TAG' && metaTagsToExclude.indexOf(t.name) === -1
    );
}
