import { Event } from '@sentry/types/esm/index';
import { eventFromUnknownInput } from '@sentry/browser/esm/eventbuilder';
import { timestampWithMs } from '@sentry/utils/esm/time';
import { SENTRY_DSN, GIT_REVISION, STACK_ID } from './config';

interface SentryDetails {
  key: string;
  project: string;
}

const ignoreEventMessages = [
  // The following are ignored due to cancelled in-flight requests
  // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios/60860369#60860369
  // and produce many sentry errors that we have no need to act upon
  'TypeError: cancelled',
  'cancelled',
  'TypeError: Failed to fetch',
  'Failed to fetch',
  'TypeError: NetworkError when attempting to fetch resource.',
  'NetworkError when attempting to fetch resource.'
];

/**
 * Taken from https://github.com/getsentry/sentry-javascript/blob/76cc56094ab6d9dd77d60d511c8bc585ab329e8f/packages/core/src/integrations/inboundfilters.ts#L176
 * Will split out the error messages and return possible types that can be filtered on.
 */
const getPossibleEventMessages = (event: Event): string[] => {
  if (event.message) {
    return [event.message];
  }
  if (event.exception) {
    try {
      const { type = '', value = '' } =
        (event.exception.values && event.exception.values[0]) || {};
      return [`${value}`, `${type}: ${value}`];
    } catch (error) {
      console.error('Failed get sentry error type', { error });
      return [];
    }
  }
  return [];
};

/**
 * Works out if the error message should actually be sent to sentry
 */
export const shouldSendToSentry = (
  error: Event,
  _event: Event,
  _hint: Event = {}
): boolean => {
  const dsn = SENTRY_DSN();
  // NOTE: the `???` is set as the default DSN in k8s_sentry.tf, when one is
  //  not configured by the stack.

  if (!dsn || dsn === '???') {
    console.error('Unable to report error due to misconfiguration.');
    // No Sentry details found or it is the build default. Exit.
    return false;
  }

  // check to see if there are and extracted error messages in the ignore list
  // NOTE currently only ignoring these errors that occur when the page initially loads
  // (jwt-manager:init) see buildSolveApi function in index.ts
  if (
    error.message &&
    error.tags?.web_sdk_origin === 'jwt-manager:init' &&
    getPossibleEventMessages(error).some(element =>
      ignoreEventMessages.includes(element)
    )
  ) {
    console.debug('Error message in ignore list, skipping', { error });
    return false;
  }

  return true;
};

/**
 * Given a Sentry DSN like `https://00185b65032d4b87a8bbb5405672fbed@sentry.io/1388473`, this will
 * split it out into the corresponding key and project values
 *
 * @param dsn Sentry DSN
 */
export const getKeyAndProjectFromDsn = (dsn: string): SentryDetails => {
  const key = dsn.substring(dsn.indexOf('//') + 2, dsn.indexOf('@'));
  const project = dsn.substring(dsn.lastIndexOf('/') + 1);
  return {
    key,
    project
  };
};

/**
 * This is taken from the useragent integration that comes with Sentry
 * https://github.com/getsentry/sentry-javascript/blob/d44a1d95e8ddd329cee10b6362a1b93d6d653aed/packages/browser/src/integrations/useragent.ts
 *
 * This is used to let Sentry know which browser this came from
 */
export const getEventRequest = (): Record<string, any> => {
  if (!global.navigator || !global.location) {
    return {};
  }

  return {
    url: global.location.href,
    headers: {
      'User-Agent': global.navigator.userAgent
    }
  };
};

/**
 * Send the error to sentry. The reason why we don't just use @sentry/browser is
 * because it is quite a large package so it is easier to just copy a couple of
 * functions as we need it
 */
export const track = (error: Error, hint: Event = {}): void => {
  console.error('Solve web SDK error:', error);

  const dsn = SENTRY_DSN();

  const { key, project } = getKeyAndProjectFromDsn(dsn);
  const headers = {
    'Content-Type': 'application/json',
    'X-Sentry-Auth': `Sentry sentry_version=7, sentry_key=${key},sentry_client=solve_web_sdk/0.1`
  };
  const errorEvent = eventFromUnknownInput(error);
  const event: Event = {
    // Include some information with the message.
    // Adapted from:
    //   - https://github.com/getsentry/sentry-javascript/blob/5.15.5/packages/core/src/baseclient.ts#L256
    //   - https://github.com/getsentry/sentry-javascript/blob/5.15.5/packages/browser/src/client.ts#L54
    ...hint,
    ...errorEvent,
    timestamp: timestampWithMs(),
    platform: 'javascript',
    tags: {
      ...hint.tags,
      ...errorEvent.tags,
      environment: process.env.NODE_ENV as string,
      stack_id: STACK_ID(),
      application: 'web-sdk'
    },
    request: getEventRequest(),
    extra: { ...hint.extra, ...errorEvent.extra, href: window.location.href },
    sdk: {
      name: 'Solve Web SDK',
      version: GIT_REVISION()
    }
  };
  const url = `https://sentry.io/api/${project}/store/`;

  if (!shouldSendToSentry(error, event, hint)) return;

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(event),
    headers
  });
};
