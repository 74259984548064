import { SolveEvent } from '../sdk';
import uuid from '../uuid';
import { EventQueueInterface, SentryTrackError } from './interfaces';

export const EVENT_STORAGE_KEY = 'solve-sdk-event-store';
export const MAX_EVENT_SEND_ERROR = 3;

export type SolveEventWrapper = {
  /**
   * A unique ID that is used to track the event during the processing pipeline.
   *
   * The `EventQueue` uses this to find processing event and either
   *  delete it it `deleteEvent`(which is called after an event is successfully
   *  sent), or update the `isSending` flag in `markEventUnprocessed`.
   *
   * The `EventSender` uses this ID to identify the event and call a Promise's
   *  resolve function once it has been sent.
   */
  id: string;
  event: SolveEvent;
  /**
   * This flag is used to track if an event is currently being sent.
   *
   * It is set in `getEventForProcessing` when an event is selected for
   *  processing, and is unset in `markEventUnprocessed`.
   *
   * This is mostly redundant as we should only ever have one event processing
   *  'thread' pulling one event at a time, but if we change that in the future
   *  in response to changing requirements the only code change will be to the
   *  EventSender.
   */
  isSending?: boolean;
  errorCount: number;
};

// Finds an event that is not currently being sent.
// Used by getEventForProcessing and hasUnprocessedEvents
function findPendingEvent(
  store: Record<string, SolveEventWrapper>
): undefined | [string, SolveEventWrapper] {
  // Cannot use `Array.find` as IE doesn't support it so resort to filter
  return Object.entries(store).filter(
    ([_, event_wrapper]) => !event_wrapper.isSending
  )[0];
}

// For tests only
export const queuedEvents: SolveEvent[] = [];
function syncEventsForTesting(events: Record<string, SolveEventWrapper>): void {
  if (process.env.NODE_ENV !== 'production') {
    queuedEvents.splice(0, queuedEvents.length);
    queuedEvents.push(...Object.values(events).map(({ event }) => event));
  }
}

export default class EventQueue implements EventQueueInterface {
  private readonly sentry: SentryTrackError;

  private readonly uniqueId: string;
  private counter = 0;
  private readonly events: Record<string, SolveEventWrapper>;

  constructor(sentry: SentryTrackError) {
    this.sentry = sentry;
    this.uniqueId = uuid();
    this.events = {};
    // Bind the callback once to prevent multiple calls to
    //  registerUnloadHandler from attaching multiple handlers.
    this.unloadHandler = this.unloadHandler.bind(this);
    syncEventsForTesting({});
  }

  init(): void {
    // On init we should load up any events previous pages put in local storage.
    this.registerUnloadHandler();
    this.loadStoredEvents();
  }

  public addEvent(event: SolveEvent): string {
    const sdk_event_id = `${this.uniqueId}-${+new Date()}-${this.counter++}`;
    this.events[sdk_event_id] = {
      id: sdk_event_id,
      event,
      errorCount: 0
    };
    syncEventsForTesting(this.events);
    return sdk_event_id;
  }

  public getEventForProcessing():
    | [string, SolveEvent]
    | [undefined, undefined] {
    const localEventForProcessing = findPendingEvent(this.events);
    if (localEventForProcessing) {
      const [id, wrapper] = localEventForProcessing;
      // Update the `isSending` flag so we know the event is trying to be sent.
      // This will prevent any accidentally concurrent event sending from
      //  sending the same event twice.
      this.events[id] = { ...wrapper, isSending: true };
      return [id, wrapper.event];
    } else {
      return [undefined, undefined];
    }
  }

  public deleteEvent(id: string): void {
    if (this.events[id]) {
      delete this.events[id];
      syncEventsForTesting(this.events);
    }
  }

  public markEventUnprocessed(id: string, error?: Error | true): void {
    if (this.events[id] && this.events[id].isSending) {
      // TODO: if there is an error increment a counter and if it gets too big we just discard the event
      delete this.events[id].isSending;
      if (error !== undefined) {
        this.events[id].errorCount = (this.events[id].errorCount || 0) + 1;
        if (this.events[id].errorCount >= MAX_EVENT_SEND_ERROR) {
          // Support bypassing the error logging by passing `error === true`
          if (error !== true) {
            this.sentry(error, {
              tags: { web_sdk_origin: 'event-queue:max-event-retries' },
              extra: { eventState: this.events[id], uniqueId: this.uniqueId }
            });
          }
          delete this.events[id];
        }
      }
    }
  }

  public hasUnprocessedEvents(): boolean {
    // Grab any unprocessed event from the two stores. If we find one then
    //  convert the array to a boolean(using `!!`)
    return !!findPendingEvent(this.events);
  }

  loadStoredEvents(): void {
    // Load all the events from localStorage saving them to this instance.
    Object.entries(this.loadLocalStorage()).forEach(([key, wrapper]) => {
      // Clear the `isSending` flag and set the error count just in case the
      //  previous page failed to write the event completely correctly.
      if (wrapper && wrapper.event) {
        this.events[key] = {
          ...wrapper,
          isSending: undefined,
          errorCount: wrapper.errorCount || 0
        };
      }
    });
    syncEventsForTesting(this.events);
    // Write an empty object back to localStorage to indicate that we are
    //  taking care of sending these events.
    this.saveLocalStorage({});
  }

  /**
   * Add an unload event handler to move all the local events into
   */
  registerUnloadHandler(): void {
    window.addEventListener('unload', this.unloadHandler);
  }

  unloadHandler(): void {
    let hasEvents = false;
    const events: Record<string, SolveEventWrapper> = {};
    Object.keys(this.events).forEach(id => {
      hasEvents = true;
      // Unset the statuses so the next page knows they didn't send
      events[id] = { ...this.events[id], isSending: undefined };
      delete this.events[id];
    });
    syncEventsForTesting(this.events);
    if (hasEvents) {
      // Save all events to local storage.
      this.saveLocalStorage({
        // Make sure anything already in local storage is preserved
        ...this.loadLocalStorage(),
        ...events
      });
    }
  }

  loadLocalStorage(): Record<string, SolveEventWrapper> {
    try {
      if (window.localStorage) {
        const value = window.localStorage.getItem(EVENT_STORAGE_KEY);
        if (value) return JSON.parse(value);
      }
    } catch (e) {
      this.sentry(e, {
        tags: { web_sdk_origin: 'event-queue:load-local-storage' }
      });
    }
    return {};
  }

  /**
   * Permit undefined values as they'll be removed when we stringify the object.
   */
  saveLocalStorage(value: Record<string, SolveEventWrapper | undefined>): void {
    try {
      if (!window.localStorage) {
        return;
      }
      window.localStorage.setItem(EVENT_STORAGE_KEY, JSON.stringify(value));
    } catch (e) {
      this.sentry(e, {
        tags: { web_sdk_origin: 'event-queue:save-local-storage' },
        extra: { value }
      });
    }
  }
}
