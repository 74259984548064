import { endsWith } from './ieCompat';

export interface PublicProfileInterface {
  hasPhoneNumber(): boolean;
  hasHashedPhoneNumber(): boolean;
  hasEmail(): boolean;
  hasHashedEmail(): boolean;
  hasIdentifier(ident: string): boolean;
  hasHashedIdentifier(ident: string): boolean;
  exists: boolean;
  identifierTypes: string[];
  encrypted?: string;
}

export type PublicProfileResponse = {
  exists: boolean;
  profile?: null | {
    encrypted?: string;
    identifierTypes: string[];
  };
  lastOrder?: null | {
    createdAt: string;
  };
};

export const HASHED_IDENT_TYPE_SUFFIXES = ['_md5', '_sha256'];

export default class PublicProfile implements PublicProfileInterface {
  exists: boolean;
  identifierTypes: string[];
  encrypted?: string;
  lastOrder: {
    exists: boolean;
    createdAt?: Date;
  };

  constructor(profile: PublicProfileResponse) {
    this.exists = profile.exists;
    // Search speed improvements could be made by creating an object and doing object key lookups.
    this.identifierTypes = profile.profile?.identifierTypes || [];
    this.encrypted = profile.profile?.encrypted;
    this.lastOrder = {
      exists: !!profile.lastOrder,
      createdAt: profile.lastOrder
        ? new Date(profile.lastOrder.createdAt)
        : undefined
    };
  }

  hasOrdered(): boolean {
    return this.lastOrder.exists;
  }

  lastOrderedAt(): Date | null | undefined {
    return this.lastOrder.createdAt;
  }

  hasPhoneNumber(): boolean {
    return this.hasIdentifier('phone_number');
  }

  hasHashedPhoneNumber(): boolean {
    return this.hasHashedIdentifier('phone_number');
  }

  hasEmail(): boolean {
    return this.hasIdentifier('email');
  }

  hasHashedEmail(): boolean {
    return this.hasHashedIdentifier('email');
  }

  hasIdentifier(ident: string): boolean {
    return this.identifierTypes.indexOf(ident) >= 0;
  }

  hasHashedIdentifier(ident: string): boolean {
    if (this.hasIdentifier(ident)) {
      // Shortcut the check if we have the exact identifier they specified
      return true;
    }
    let raw_ident = ident;
    //  Otherwise, the user may have specified `ident_md5`, but we will also check for `ident` and `ident_sha256`
    HASHED_IDENT_TYPE_SUFFIXES.forEach(suffix => {
      if (endsWith(ident, suffix)) {
        raw_ident = ident.substr(0, ident.length - suffix.length);
      }
    });
    return (
      this.hasIdentifier(raw_ident) ||
      HASHED_IDENT_TYPE_SUFFIXES.some(suffix =>
        this.hasIdentifier(raw_ident + suffix)
      )
    );
  }
}
