/**
 * A simple wrapper around localhost that gets, sets, and removes keys
 * that are stored in the browser for profile tracking
 *
 */

import {
  LinkingId,
  LinkingIdNotGenerated,
  LinkingIdStorage,
  LINKING_ID_NOT_GENERATED
} from './linkingId';
import { Sessions, SessionsStorage } from './sessions';
import { ReferrerDataAccess } from './referrer';

export const hasLocalStorage = (): boolean => {
  try {
    const testKeyValue = '_s_';
    localStorage.setItem(testKeyValue, testKeyValue);
    localStorage.removeItem(testKeyValue);
    return true;
  } catch (err) {
    return false;
  }
};

export const parseDate = (
  untrustedInput: string | number | null | undefined
): Date | undefined => {
  if (untrustedInput === null || untrustedInput === undefined) return undefined;
  try {
    // The 'valueOf' the date will be NaN if the date input is invalid.
    const date = new Date(untrustedInput);
    // Cannot use `=== NaN` as `NaN` has a special property of never being
    //  equal to itself.
    // Cannot use `Number.isNaN` as it is not supported in IE11.
    // Note that `isNaN` returns `true` if the value is not able to be used
    //  as a number(like say `"foobar"`). We don't have to worry about this
    //  as `.valueOf` will always return a number.
    if (isNaN(date.valueOf())) {
      return undefined;
    } else {
      return date;
    }
  } catch (e) {
    // `new Date` doesn't throw, but keep this just in case.
    /* istanbul ignore next */
    return undefined;
  }
};

export const SESSION_DESTROYED_MAGIC_VALUE = 'destroy';
export const enum SessionRegenerationReason {
  CREATED_DUE_TO_NO_EXISTING_ID = 'created_due_to_no_existing_id',
  RECREATED_DUE_TO_SOLVE_DESTROY_INVOCATION = 'recreated_due_to_solve_destroy_invocation',
  RECREATED_DUE_TO_EXPIRY = 'recreated_due_to_expiry',
  RECREATED_DUE_TO_ERROR = 'recreated_due_to_error',
  EXTRACTED_FROM_REFERRER = 'extracted_from_referrer',
  RETURNED_FROM_API = 'returned_from_api',
  LOCAL_STORAGE_DISABLED = 'local_storage_disabled',
  NOT_RECREATED = 'not_recreated'
}

export type SessionWithReason<T> = {
  reason: SessionRegenerationReason;
  value: T | undefined;
};

export interface SessionInterface {
  addSessionParameter(): void;
  getSessions(options?: {
    cacheReason?: boolean;
    extendExistingSession?: boolean;
  }): SessionWithReason<Sessions>;
  getLinkingId(options?: {
    cacheReason?: boolean;
    generateIfNotPresent?: boolean;
    extendExistingSession?: boolean;
  }): SessionWithReason<LinkingId> | LinkingIdNotGenerated;
  setLinkingId(linkingId: string): void;
  destroy(): void;
}
/**
 * Small abstraction class over how we store and retrieve session ids for the
 * current browser session. Currently uses localStorage
 * as the storage engine, and uuid (https://www.npmjs.com/package/uuid) to
 * generate the session id.
 */
export class SessionStorage implements SessionInterface {
  private readonly referrer: ReferrerDataAccess;
  private readonly linkingId: LinkingIdStorage;
  private linkingIdLastReason: SessionRegenerationReason | null = null;
  private readonly sessions: SessionsStorage;
  private sessionsLastReason: SessionRegenerationReason | null = null;

  constructor(referrerSessionsOverride = false) {
    this.referrer = new ReferrerDataAccess(this, referrerSessionsOverride);
    this.linkingId = new LinkingIdStorage(this.referrer);
    this.sessions = new SessionsStorage(this.referrer);
  }

  public addSessionParameter(): void {
    this.referrer.addParameter();
  }

  public setupSessionParameter(): void {
    this.referrer.setupParameterUpdater();
    this.referrer.addParameter();
  }

  public getSessions({
    cacheReason = false,
    extendExistingSession = true
  } = {}): SessionWithReason<Sessions> {
    let { value, reason } = this.sessions.getSessions({
      extendExistingSession
    });
    if (cacheReason) {
      if (reason !== SessionRegenerationReason.NOT_RECREATED) {
        this.sessionsLastReason = reason;
      }
    } else {
      if (reason === SessionRegenerationReason.NOT_RECREATED) {
        reason = this.sessionsLastReason || reason;
      }
      this.sessionsLastReason = null;
    }
    return { value, reason };
  }

  public getLinkingId({
    cacheReason = false,
    generateIfNotPresent = true,
    extendExistingSession = true
  } = {}): SessionWithReason<LinkingId> | LinkingIdNotGenerated {
    const linkingId = this.linkingId.getLinkingId({
      generateIfNotPresent,
      extendExistingSession
    });
    if (cacheReason) {
      if (
        linkingId !== LINKING_ID_NOT_GENERATED &&
        linkingId.reason !== SessionRegenerationReason.NOT_RECREATED
      ) {
        this.linkingIdLastReason = linkingId.reason;
      }
    } else {
      if (
        linkingId !== LINKING_ID_NOT_GENERATED &&
        linkingId.reason === SessionRegenerationReason.NOT_RECREATED
      ) {
        linkingId.reason = this.linkingIdLastReason || linkingId.reason;
      }
      this.linkingIdLastReason = null;
    }
    return linkingId;
  }

  setLinkingId(linkingId: LinkingId): void {
    if (this.linkingId.setLinkingId(linkingId)) {
      // Only set the reason if the linking ID being returned is chaning our linking ID; or is new.
      this.linkingIdLastReason = SessionRegenerationReason.RETURNED_FROM_API;
    }
  }

  /**
   * Destroys the current sessions and creates a new one
   */
  public destroy(): void {
    this.linkingId.destroy();
    this.sessions.destroy();
  }
}
