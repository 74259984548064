import uuid from '../uuid';
import {
  hasLocalStorage,
  parseDate,
  SessionRegenerationReason,
  SessionWithReason,
  SESSION_DESTROYED_MAGIC_VALUE
} from '.';
import { ReferrerDataAccessInterface } from './referrer';

export const sessionsDbKey = 'solve_sessions';
export const solveSessionKey = 'solve.sdk';
export const sessionTimestampDbKey = 'solve_session_timestamp';
export const sessionTimeoutMinutes = 30;

export type Sessions = Record<string, string>;

/**
 * Gets the session time from local storage, if it exists
 */
const getSessionTime = (): string | null => {
  return localStorage.getItem(sessionTimestampDbKey);
};

/**
 * Sets the session time in local storage to right now
 */
const setSessionTime = (): void => {
  return localStorage.setItem(sessionTimestampDbKey, new Date().toISOString());
};

/**
 * Creates a new session id and stores it. Returns the new sessions object.
 * TODO: handle the case where localStorage is unavailable a bit better
 */
const setNewSessions = (): { [solveSessionKey]: string } => {
  const sessions = { [solveSessionKey]: uuid() };
  setSessions(sessions);
  // Also reset the session time
  setSessionTime();

  return sessions;
};

const setSessions = (newSessions: Sessions): Sessions => {
  localStorage.setItem(sessionsDbKey, JSON.stringify(newSessions));

  return newSessions;
};

const getSessions = (): null | Sessions => {
  const stored = localStorage.getItem(sessionsDbKey);
  if (!stored) {
    // Nothing exists yet so return all new session details
    return null;
  }

  let parsedSessions: unknown;
  try {
    parsedSessions = JSON.parse(stored);
  } catch (err) {
    console.error('Error parsing sessions', err);
    throw new Error(
      'Unable to fetch sessions. Stored session was not valid JSON'
    );
  }
  if (Array.isArray(parsedSessions) || typeof parsedSessions !== 'object') {
    // Session is not an object. Recreate
    console.error('Error getting sessions via session time', parsedSessions);
    throw new Error(
      'Unable to fetch sessions. Stored session was not the correct type'
    );
  }
  return parsedSessions as Sessions | null;
};

export class SessionsStorage {
  private readonly referrer: ReferrerDataAccessInterface;
  private readonly hasLocalStorage: boolean;
  constructor(referrer: ReferrerDataAccessInterface) {
    this.referrer = referrer;
    this.hasLocalStorage = hasLocalStorage();
  }

  destroy(): void {
    if (this.hasLocalStorage) {
      localStorage.setItem(
        sessionTimestampDbKey,
        // This special value is used to detect if the previous session was
        //  explicitly destroyed
        SESSION_DESTROYED_MAGIC_VALUE
      );
      // Remove the session from localstorage so the next invocation will
      //  recreate it with new values.
      localStorage.removeItem(sessionsDbKey);
    }
  }

  getSessions({
    extendExistingSession = true
  } = {}): SessionWithReason<Sessions> {
    if (!this.hasLocalStorage) {
      return {
        reason: SessionRegenerationReason.LOCAL_STORAGE_DISABLED,
        value: undefined
      };
    }

    const currentSessionTime = getSessionTime();
    if (currentSessionTime === SESSION_DESTROYED_MAGIC_VALUE) {
      // Session was manually destroyed, create a new session. This check needs
      //  to happen before checking the session's local storage as the destroy
      //  function will clear that key to ensure backwards compatibility.
      return {
        reason:
          SessionRegenerationReason.RECREATED_DUE_TO_SOLVE_DESTROY_INVOCATION,
        value: setNewSessions()
      };
    }

    const sessionTimeAsDate = parseDate(currentSessionTime) ?? new Date();
    const sessionTimeBoundary = new Date(
      sessionTimeAsDate.getTime() + sessionTimeoutMinutes * 60000
    );
    let parsedSessions;
    try {
      parsedSessions = getSessions();
    } catch (e) {
      return this._findSessionsOrCreateNew(
        SessionRegenerationReason.RECREATED_DUE_TO_ERROR
      );
    }
    if (!parsedSessions) {
      return this._findSessionsOrCreateNew(
        SessionRegenerationReason.CREATED_DUE_TO_NO_EXISTING_ID
      );
    } else if (sessionTimeBoundary >= new Date()) {
      // We're still within the session boundary. Extend the current session and return session details
      // as is
      if (extendExistingSession) {
        setSessionTime();
      }
      if (!parsedSessions[solveSessionKey]) {
        // The session does not have the correct key.
        // Extract the solve session from the referrer if it exists, then merge
        //  it wit the existing sessions.
        const { value, reason } = this._findSessionsOrCreateNew(
          SessionRegenerationReason.CREATED_DUE_TO_NO_EXISTING_ID
        );

        const newSessions = setSessions({
          ...parsedSessions,
          ...value
        });
        return { value: newSessions, reason };
      }

      return {
        reason: SessionRegenerationReason.NOT_RECREATED,
        value: parsedSessions
      };
    } else {
      // Else the session time has passed. Reset all sessions
      return this._findSessionsOrCreateNew(
        SessionRegenerationReason.RECREATED_DUE_TO_EXPIRY
      );
    }
  }

  private _findSessionsOrCreateNew(
    fallbackReason: SessionRegenerationReason
  ): SessionWithReason<Sessions> {
    // This function shouldn't be called if local storage is absent.
    // `getSessions` will take care of this.
    const referrerSession = this.referrer.extractSession();
    if (referrerSession.valid) {
      const session = { [solveSessionKey]: referrerSession.sessionId };

      setSessions(session);
      setSessionTime();

      return {
        value: session,
        reason: SessionRegenerationReason.EXTRACTED_FROM_REFERRER
      };
    } else {
      return {
        value: setNewSessions(),
        reason: fallbackReason
      };
    }
  }
}
