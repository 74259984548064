import cookies from 'browser-cookies';
import { SentryTrackError } from '../newRequest/interfaces';
import { SolveEvent, SolveWindow } from '../sdk';
import { ShopConfigFetcher } from './shopConfig';

const shopifyCartCookieName = 'cart';

const getCartId = (): string | null => cookies.get(shopifyCartCookieName);

export const createOnCartCreationPromise = (
  pageWindow: Window
): Promise<string> =>
  new Promise(resolve => {
    const timerId = pageWindow.setInterval(() => {
      const cartId = getCartId();
      if (cartId === null) return;

      clearInterval(timerId);
      resolve(cartId);
    }, 500);
  });

// TODO some code is in sdk snippet and some is in here. Could move to using
// just sdk snippets so we can write more tests

export default class Shopify {
  private readonly shopConfig: ShopConfigFetcher;
  private readonly pageWindow: Window;
  private readonly pageDocument: Document;
  private readonly onCartCreation: (pageWindow: Window) => Promise<string>;
  private readonly sentryTrack: SentryTrackError;
  // Allow injection of fake window/document for tests
  constructor(
    shopConfig: ShopConfigFetcher,
    {
      pageWindow = window,
      pageDocument = document,
      onCartCreation = createOnCartCreationPromise,
      sentryTrack
    }: {
      pageWindow?: Window;
      pageDocument?: Document;
      onCartCreation?: (pageWindow: Window) => Promise<string>;
      sentryTrack: SentryTrackError;
    }
  ) {
    this.shopConfig = shopConfig;
    this.pageWindow = pageWindow;
    this.pageDocument = pageDocument;
    this.onCartCreation = onCartCreation;
    this.sentryTrack = sentryTrack;
  }

  public registerEventHandlers = (solve: SolveWindow): void => {
    // Note: we don't have to set up a `this` binding for these callback
    // functions manually, because they are defined as arrow functions and so
    // are bound on object initialisation
    solve.addLifecycleHookHandler('beforesend', this.setCartId);
    solve.addLifecycleHookHandler('beforesend', this.setOrderId);
    solve.addLifecycleHookHandler('beforesend', this.setProductData);

    if (getCartId() === null) {
      this.onCartCreation(this.pageWindow).then(() => {
        // Send an event, and due to the above lifecycle handlers the cart_id
        // will be put on the event (). The event will of course contain the
        // session_id as per usual.
        //
        // Don't care about the result
        //
        // This is here because if the user does not go to a different page
        // after creating the cart (the cart will not exist until something is
        // added to it), the cart will not get linked to a session. This is
        // because we relied on the next pageview containing the cart_id and
        // session_id. This may not exist if the user simply closes the tab, or
        // navigates to checkout immediately and never views another page on
        // the site after completing the order (I guess this is more likely to
        // happen if there's a custom payment provider that has a final order
        // completion page that is not on Shopify's site, and the user closes
        // the tab then). See issue
        // https://solvedata.atlassian.net/browse/SOLV-3762
        solve.customEvent('shopify_cart_create', { persist: false });
      });
    }
  };

  /**
   * Is the current page a shopify page. Note that this will return false on a
   * Shopify checkout page iff the store is a Shopify Plus store and the SDK is
   * included on the checkout page. The tricky part of the checkout page is
   * that the `Shopify.shop` value does not exist. To ensure the `Shopify.shop`
   * value exists when Shopify code runs, we only return true if that value
   * exists.
   *
   * Note that the order thank_you page has a non-null `window.Shopify.shop`
   */
  public isShopifyPage = (): boolean => !!this.shopConfig();

  /**
   * Ugly hack to find the order number. See here for info about why we had to do
   * this
   * https://solvedata.atlassian.net/wiki/spaces/S/pages/1055555585/Shopify+Buy+Now+Order-cart-session+association
   *
   * Will return null most of the time as we won't be on the right page for this
   * to grab the order number from.
   */
  public getCreatedOrderNumber = (): string | null => {
    const config = this.shopConfig();
    if (!config) return null;

    if (!this.pageDocument.location.pathname.match(/\/thank_you/)) return null;
    // User has clicked pay and we are on the next page

    const orderNumberElement =
      this.pageDocument.querySelector('.os-order-number');
    if (orderNumberElement === null) return null;

    // See Confluence for the reason this prefix/suffix configuration is needed
    // https://solvedata.atlassian.net/wiki/spaces/S/pages/794263574/Shopify+Data+Learnings+and+Patterns+we+ve+discovered#Order-Number-Formatting
    let regexString = 'Order (\\S+)';

    // must be explicitly true
    if (config.useLegacyOrderId) {
      const { prefix, suffix } = config.orderNumberFormat || {};
      if (typeof prefix !== 'string' || typeof suffix !== 'string')
        throw new Error(
          'Config shopify.orderNameFormat not set with prefix and suffix'
        );

      regexString = `Order ${prefix}(\\d+)${suffix}`;
    }

    const regex = new RegExp(regexString, 'i');

    const orderNumberText = orderNumberElement.innerHTML;

    const match = orderNumberText.match(regex);
    if (match === null) {
      this.sentryTrack(
        new Error(
          `Shopify order thank_you page did not have matching order name`
        ),
        {
          tags: { web_sdk_origin: 'Shopify.getCreatedOrderNumber' },
          extra: {
            config,
            regexString,
            orderNumberText
          }
        }
      );
      return null;
    }

    // Return the capture group (the order number)
    return match[1];
  };

  public extractProductIdFromPage = (): string | null => {
    if (!this.isShopifyPage()) return null;

    const graphQlId = (this.pageWindow as any).ShopifyAnalytics?.meta?.product
      ?.gid;
    if (typeof graphQlId !== 'string') return null;

    const match = graphQlId.match(/gid:\/\/shopify\/Product\/(\d+)/);
    if (match === null) return null;

    return match[1];
  };

  public store = (): string | undefined => {
    return this.shopConfig()?.storeName;
  };

  private setCartId = (event: SolveEvent): Promise<SolveEvent> => {
    if (!this.isShopifyPage() || typeof event.cart_id === 'string') {
      // This key already exists. Don't do anything
      return Promise.resolve(event);
    }

    const cartId = getCartId();
    if (cartId === null) return Promise.resolve(event);

    return Promise.resolve({
      ...event,
      cart_id: cartId,
      store: event.store || this.store()
    });
  };

  private setOrderId = (event: SolveEvent): Promise<SolveEvent> => {
    if (!this.isShopifyPage() || typeof event.order_id === 'string') {
      // This key already exists. Don't do anything
      return Promise.resolve(event);
    }

    const orderId = this.getCreatedOrderNumber();
    if (orderId === null) return Promise.resolve(event);

    return Promise.resolve({
      ...event,
      order_id: orderId,
      store: event.store || this.store()
    });
  };

  private setProductData = (event: SolveEvent): Promise<SolveEvent> => {
    if (!this.isShopifyPage() || typeof event.product_id === 'string') {
      // This key already exists. Don't do anything
      return Promise.resolve(event);
    }

    const productId = this.extractProductIdFromPage();
    if (productId === null) return Promise.resolve(event);

    return Promise.resolve({
      ...event,
      product_id: productId,
      store: event.store || this.store()
    });
  };
}
