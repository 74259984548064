import {
  ExtractedSessionInformation,
  extractSession,
  generateParameter,
  REFERRER_EXPIRY_MS
} from './encoding';

import { SessionInterface } from '..';
import { solveSessionKey } from '../sessions';
import { LINKING_ID_NOT_GENERATED } from '../linkingId';

export function isIosBrowser(userAgent = window.navigator.userAgent): boolean {
  // This will not detect iPads in desktop browsing mode, as their user
  //  agents are identical to Safari on Mac OS
  const isIOS = /iPad|iPhone|iPod/.test(userAgent);

  return isIOS;
}

export interface ReferrerDataAccessInterface {
  addParameter(): void;
  extractSession(): ExtractedSessionInformation;
}

export class ReferrerDataAccess implements ReferrerDataAccessInterface {
  private _active: boolean;
  private _storage: SessionInterface;
  private _parameterUpdateInterval: null | ReturnType<typeof setInterval>;
  constructor(storage: SessionInterface, override: boolean) {
    this._storage = storage;
    this._active = override || isIosBrowser();

    if (window.location.hostname === 'mmlafleur.com') {
      this._active = false;
    }

    this._parameterUpdateInterval = null;
  }

  setupParameterUpdater(): void {
    if (this._active && this._parameterUpdateInterval === null)
      // We update the parameter more frequently than the parameter expires to ensure it will always
      //  be valid just before we change pages.
      this._parameterUpdateInterval = setInterval(
        this.addParameter,
        REFERRER_EXPIRY_MS / 2
      );
  }

  addParameter = (): void => {
    if (this._active) {
      const { value: sessions } = this._storage.getSessions({
        cacheReason: true,
        extendExistingSession: false
      });
      const lidAndReason = this._storage.getLinkingId({
        cacheReason: true,
        extendExistingSession: false
      });

      if (!sessions || !sessions[solveSessionKey]) return;
      if (lidAndReason === LINKING_ID_NOT_GENERATED || !lidAndReason.value)
        return;

      const linkingId = lidAndReason.value;
      const sessionId = sessions[solveSessionKey];

      const currentUrl = new URL(document.location.toString());

      try {
        currentUrl.searchParams.set(
          'slv_rt',
          generateParameter({ sessionId, linkingId })
        );
        const updatedPathName = `${document.location.pathname}${currentUrl.search}${document.location.hash}`;
        window.history.replaceState(
          window.history.state,
          document.title,
          updatedPathName
        );
      } catch (e) {}
    }
  };

  extractSession(): ExtractedSessionInformation {
    if (this._active && document.referrer) {
      const referrer = new URL(document.referrer, document.location.toString());
      const rt = referrer.searchParams.get('slv_rt');
      if (rt) {
        return extractSession(rt);
      }
    }
    return { valid: false };
  }
}
