import { endsWith } from '../ieCompat';

export type FirstPartyApiUrls = string | string[];
export type FirstPartyDomains = Record<string, FirstPartyApiUrls>;

/**
 * For backwards compatibility. Originally we stored the full `/events` URL as
 *  the API URL when, really, we should have only stored the URL that comes
 *  before `/events` as that would allow us to use the URL for other endpoints
 *  also. In order to keep backwards compatibility, if the url ends with
 *  `/events` then automagically remove that from the url and override the
 *  original config. This means the SDK will work with both URL formats.
 */
export const createNonEventApiUrl = (apiUrl: string, https: boolean) => {
  if (endsWith(apiUrl, '/events')) {
    return domainToUrl(
      apiUrl.substring(0, apiUrl.length - '/events'.length),
      https
    );
  }
  return domainToUrl(apiUrl, https);
};

function domainToUrl(domain: string, https: boolean) {
  const [left, right] = domain.split('://', 2);
  let cleanedDomain;
  if (right === undefined) {
    cleanedDomain = (https ? 'https://' : 'http://') + left;
  } else {
    cleanedDomain = (https ? 'https://' : 'http://') + right;
  }
  if (endsWith(cleanedDomain, '/'))
    return cleanedDomain.substring(0, cleanedDomain.length - 1);
  else return cleanedDomain;
}

function normaliseFirstPartyApiUrls(
  apiDomain: FirstPartyApiUrls,
  https: boolean
): string[] {
  if (Array.isArray(apiDomain))
    return apiDomain.map(domain => domainToUrl(domain, https));
  else return [domainToUrl(apiDomain, https)];
}

/**
 * Choose the best-matching first-party URLs listed in the input `domains` object.
 *
 * @param domains The customer-defined object with a mapping of domain-suffixes and
 *              the first-party URLs to use.
 * @param documentDomain The current domain name. Defaults to the document's
 *                        domain. Only use in tests
 * @returns A list of the first-party URLs to use on this page. Can be empty if
 *           no first-party URLs are to be used.
 */
export function fetchFirstPartyDomainList(
  domains: undefined | FirstPartyDomains,
  { https = true, documentDomain = document.location.hostname } = {}
): string[] {
  if (!domains) {
    return [];
  }
  // Cannot use Object.entries as it doesn't exist in IE.
  const domainsToCheck = Object.keys(domains)
    // Sort so longest URLs are checked first.
    .sort((urlA, urlB) => urlB.length - urlA.length);

  for (const urlKey of domainsToCheck) {
    const url = urlKey.charAt(0) === '.' ? urlKey.substring(1) : urlKey;

    if (documentDomain === url) {
      return normaliseFirstPartyApiUrls(domains[urlKey], https);
    } else if (endsWith(documentDomain, '.' + url)) {
      // The suffix can fit into the document's domain; and the domain ends with the suffix
      return normaliseFirstPartyApiUrls(domains[urlKey], https);
    }
  }
  return [];
}
