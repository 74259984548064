import { _Solve } from './sdk';
import {
  ShopConfigFetcher,
  SolveShopifyShopConfig
} from './shopify/shopConfig';

export const getCurrentScriptUrl = ({
  pageDocument = document
}: { pageDocument?: Document } = {}): undefined | URL => {
  let script;
  try {
    script = pageDocument.currentScript;
  } catch (e) {
    // Ignore
  }
  if (script && 'src' in script) {
    return new URL(script.src);
  } else {
    // Probably IE(*shakes fist*), we need to resort to trying to locate the
    //  script from all the scripts loaded on the page
    const scripts = pageDocument.scripts;
    for (let i = 0; i < scripts.length; i++) {
      const src = scripts[i].src;
      // A full URL, with an ending path of `/sdk.js`, and an `api_key` query parameter.
      if (/^https?:\/\/.*\/sdk\.js\?.*api_key=.+$/.test(src)) {
        // Probably our SDK, hopefully
        return new URL(src);
      }
    }
  }
  // Probably not possible, Probably should be reported to sentry if it does happen.
  return undefined;
};

export const retrieveSdkParamsFromScriptUrl = ({
  pageDocument = document
}: { pageDocument?: Document } = {}): {
  solveConfig: _Solve;
  shopify: ShopConfigFetcher;
} => {
  const url = getCurrentScriptUrl({ pageDocument });
  if (!url) throw new Error('Unable to initialise SDK using URL parameters.');

  const [apiKey, apiUrl] = [
    url.searchParams.get('api_key'),
    url.searchParams.get('api_url')
  ];
  if (apiKey === null || apiUrl === null) {
    throw new Error('Unable to initialise SDK using URL parameters.');
  }

  const [storeName, orderNumberPrefix, orderNumberSuffix] = [
    url.searchParams.get('store_name'),
    url.searchParams.get('order_number_prefix'),
    url.searchParams.get('order_number_suffix')
  ];

  const solveConfig: _Solve = { apiKey, apiUrl };
  let shopifyConfig: SolveShopifyShopConfig | undefined;
  if (storeName === null) {
    shopifyConfig = undefined;
  } else {
    if (orderNumberPrefix && orderNumberSuffix) {
      shopifyConfig = {
        storeName,
        orderNumberFormat: {
          prefix: orderNumberPrefix,
          suffix: orderNumberSuffix
        },
        useLegacyOrderId: true
      };
    } else if (
      (!orderNumberPrefix && orderNumberSuffix) ||
      (!orderNumberSuffix && orderNumberPrefix)
    ) {
      // if only one of prefix or suffix is set then we cannot reliably setup the config so return undefined
      shopifyConfig = undefined;
    } else {
      shopifyConfig = {
        storeName,
        useLegacyOrderId: false
      };
    }
  }

  return { solveConfig, shopify: () => shopifyConfig };
};
