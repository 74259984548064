import uuid from '../uuid';
import {
  hasLocalStorage,
  parseDate,
  SessionRegenerationReason,
  SessionWithReason,
  SESSION_DESTROYED_MAGIC_VALUE
} from '.';
import { ReferrerDataAccessInterface } from './referrer';

export const linkingIdDbKey = 'solve_linking_id';
export const linkingIdTimestampDbKey = 'solve_linking_id_timestamp';
export const linkingIdTimeoutDays = 30;

export type LinkingId = string;

/**
 * Sets the linking id time in local storage to right now
 */
const setLinkingIdTime = (): void => {
  return localStorage.setItem(
    linkingIdTimestampDbKey,
    new Date().toISOString()
  );
};

/**
 * Creates a new linking id and stores it.
 */
const setNewLinkingId = (): LinkingId => {
  return setLinkingId(uuid());
};

const setLinkingId = (linkingId: LinkingId): LinkingId => {
  localStorage.setItem(linkingIdDbKey, linkingId);
  setLinkingIdTime();
  return linkingId;
};

/**
 * Gets the linking id time from local storage, if it exists
 */
const getLinkingIdTime = (): string | null => {
  return localStorage.getItem(linkingIdTimestampDbKey);
};

// Make the type definitions strict, and the generated JavaScript small by using
//  a `const enum`. We use this type to indicate that we didn't find a Linking ID
//  and would have generated one, but the caller asked us not to(because they
//  will ask the server to try and extract one from a cookie or generate one for
//  us).
const enum LinkingIdNotGeneratedEnum {
  // This must be a falsy value because we use `getLinkingId() || {}` to allow
  //  us to access the `.value` property without needing lots of lines.
  VALUE = 0
}
export type LinkingIdNotGenerated = typeof LinkingIdNotGeneratedEnum['VALUE'];
export const LINKING_ID_NOT_GENERATED: LinkingIdNotGenerated =
  LinkingIdNotGeneratedEnum.VALUE;

export class LinkingIdStorage {
  private readonly referrer: ReferrerDataAccessInterface;
  private readonly hasLocalStorage: boolean;
  constructor(referrer: ReferrerDataAccessInterface) {
    this.referrer = referrer;
    this.hasLocalStorage = hasLocalStorage();
  }

  destroy(): void {
    if (this.hasLocalStorage) {
      localStorage.setItem(
        linkingIdTimestampDbKey,
        SESSION_DESTROYED_MAGIC_VALUE
      );
      localStorage.removeItem(linkingIdDbKey);
    }
  }

  /**
   *
   * @param linkingId The linking ID to set
   * @returns `true` when the Linking ID given is different to the one stored
   */
  setLinkingId(linkingId: LinkingId): boolean {
    if (this.hasLocalStorage) {
      const existingLid = localStorage.getItem(linkingIdDbKey);
      setLinkingId(linkingId);
      return existingLid !== linkingId;
    }
    return false;
  }

  getLinkingId({
    generateIfNotPresent = true,
    extendExistingSession = true
  } = {}): SessionWithReason<LinkingId> | LinkingIdNotGenerated {
    if (!this.hasLocalStorage) {
      return {
        reason: SessionRegenerationReason.LOCAL_STORAGE_DISABLED,
        value: undefined
      };
    }

    const currentLinkingIdTime = getLinkingIdTime();
    if (currentLinkingIdTime === SESSION_DESTROYED_MAGIC_VALUE) {
      // Session was manually destroyed, create a new session. This check needs
      //  to happen before checking the session's local storage as the destroy
      //  function will clear that key to ensure backwards compatibility.
      return {
        reason:
          SessionRegenerationReason.RECREATED_DUE_TO_SOLVE_DESTROY_INVOCATION,
        value: setNewLinkingId()
      };
    }
    const linkingIdTimeAsDate = parseDate(currentLinkingIdTime) ?? new Date();
    const linkingIdTimeBoundary = new Date(
      linkingIdTimeAsDate.getTime() + linkingIdTimeoutDays * 1000 * 60 * 60 * 24
    );

    const stored = localStorage.getItem(linkingIdDbKey);
    if (!stored) {
      // Nothing exists yet so return all new linking id details
      return this._findLinkingIdOrCreateNew(
        SessionRegenerationReason.CREATED_DUE_TO_NO_EXISTING_ID,
        generateIfNotPresent
      );
    }

    // Is the time within the timeout day boundary?
    if (linkingIdTimeBoundary >= new Date()) {
      // We're still within the linking id boundary. Extend the current linking
      //  id and return linking id details as is
      if (extendExistingSession) {
        setLinkingIdTime();
      }
      return { reason: SessionRegenerationReason.NOT_RECREATED, value: stored };
    }

    // Else the linking id time has passed. Reset all linking ids
    return this._findLinkingIdOrCreateNew(
      SessionRegenerationReason.RECREATED_DUE_TO_EXPIRY,
      generateIfNotPresent
    );
  }

  private _findLinkingIdOrCreateNew(
    fallbackReason: SessionRegenerationReason,
    generateIfNotPresent: boolean
  ): SessionWithReason<LinkingId> | LinkingIdNotGenerated {
    const referrerSession = this.referrer.extractSession();
    if (referrerSession.valid) {
      this.setLinkingId(referrerSession.linkingId);

      return {
        value: referrerSession.linkingId,
        reason: SessionRegenerationReason.EXTRACTED_FROM_REFERRER
      };
    } else if (generateIfNotPresent) {
      return {
        value: setNewLinkingId(),
        reason: fallbackReason
      };
    } else {
      return LINKING_ID_NOT_GENERATED;
    }
  }
}
