import { addRevision } from './api';

export enum JwtFetchResultType {
  Success,
  AuthenticationFailure,
  ConnectionFailure
}

export type JwtFetchSuccess = {
  tag: JwtFetchResultType.Success;
  jwt: string;
  localExpiry: number;
};
export type JwtFetchFailed = {
  tag:
    | JwtFetchResultType.AuthenticationFailure
    | JwtFetchResultType.ConnectionFailure;
  error: Error;
};

export function determineJwtExpiry(
  requestStartTime: number,
  jwt: string
): number {
  const jwtData = JSON.parse(window.atob(jwt.split('.')[1]));

  if (jwtData.iat === undefined) {
    return jwtData.exp * 1000;
  } else {
    // We try not to assume the user's clock matches the servers
    // We find how long the token is valid for and add it to the time the user started the request.
    const tokenDurationMs = (jwtData.exp - jwtData.iat) * 1000;
    return requestStartTime + tokenDurationMs;
  }
}

export const GET_TOKEN_URL = '/auth/get_token';

export async function fetchJwtFromEndpoint(
  apiUrl: string,
  apiKey: string,
  revision?: string
): Promise<JwtFetchSuccess | JwtFetchFailed> {
  // Record the time the request started so we can know when the JWT is due to
  //  expire. We could use the time encoded in the JWT; but if the computer's
  //  clock is out of sync it could cause issues.
  const requestStartTime = Date.now();
  let response;
  try {
    response = await fetch(`${apiUrl}${GET_TOKEN_URL}`, {
      method: 'GET',
      headers: addRevision(
        {
          Authorization: `Basic ${apiKey}`
        },
        revision
      )
    });
  } catch (e) {
    if (e.name === 'TypeError') {
      // Network errors are supposed to be type errors. Time will tell if this is actually the case.
      const result: JwtFetchFailed = {
        tag: JwtFetchResultType.ConnectionFailure,
        error: e
      };
      return result;
    } else {
      throw e;
    }
  }

  if (!response.ok) {
    // Fetch failed. Probably because the API Key was invalid, or our servers
    //  are down. Throw an error and let the caller handle retrying the fetch.
    const result: JwtFetchFailed = {
      tag: JwtFetchResultType.AuthenticationFailure,
      error: new Error(
        `Unable to fetch JWT, server returned ${response.status} ${response.statusText}`
      )
    };
    return result;
  } else {
    const jwt = await response.text();

    const jwtExpiry = determineJwtExpiry(requestStartTime, jwt);

    const result: JwtFetchSuccess = {
      tag: JwtFetchResultType.Success,
      jwt,
      // Mark the token as expired 5 seconds before the JWT actually expires.
      localExpiry: jwtExpiry - 5000
    };
    return result;
  }
}
