import { PublicProfileResponse } from '../publicProfile';
import { SolveEvent, _Solve } from '../sdk';
import { SessionInterface } from '../storage';
import {
  createNonEventApiUrl,
  fetchFirstPartyDomainList
} from './apiUrlHelpers';
import { Sessions, solveSessionKey } from '../storage/sessions';
import GraphQlApi from './api';
import EventSender from './event';
import {
  GraphQlApiInterface,
  EventSenderInterface,
  EventQueueInterface,
  JwtManagerInterface,
  SolveApiInterface,
  SentryTrackError
} from './interfaces';
import JwtManager from './jwt';
import EventQueue from './storage';
import {
  LinkingId,
  LinkingIdNotGenerated,
  LINKING_ID_NOT_GENERATED
} from '../storage/linkingId';
import { PublicCartMutationOptions, PublicCartResponse } from '../publicCart';

export const PUBLIC_PROFILE_QUERY =
  'query($session_id: String, $linking_id: String) { ' +
  'public_profile(session_id: $session_id, linking_id: $linking_id) { ' +
  'exists profile { encrypted identifierTypes } ' +
  'lastOrder { createdAt }' +
  '} }';

export const PUBLIC_CART_QUERY = `
  query public_cart($id: String!, $provider: String!) {
    public_cart(id: $id, provider: $provider) {
      id
      provider
      currency
      attributes
      order_id
      created_at
      status
      reached_checkout
      items {
        price
        quantity
        product_id
        sku
        title
      }
      adjustments {
        description
        amount
      }
    }
  }
`;

export const PUBLIC_CART_CREATE_OR_UPDATE_MUTATION = `
  mutation create_or_update_public_cart($input: PublicCartInput!, $options: PublicCartOptions) {
    create_or_update_public_cart(input: $input, options: $options) {
      id
      provider
      currency
      attributes
      order_id
      created_at
      status
      reached_checkout
      items {
        price
        quantity
        product_id
        sku
        title
      }
      adjustments {
        description
        amount
      }
    }
  }
`;

export const PUBLIC_CART_CONVERT_MUTATION = `
  mutation convert_public_cart($id: String!, $provider: String!, $order_id: String, $options: PublicCartOptions) {
    convert_public_cart(id: $id, provider: $provider, order_id: $order_id, options: $options) {
      id
      provider
      currency
      attributes
      order_id
      created_at
      status
      reached_checkout
      items {
        price
        quantity
        product_id
        sku
        title
      }
      adjustments {
        description
        amount
      }
    }
  }
`;

export class SolveApi implements SolveApiInterface {
  private readonly api: GraphQlApiInterface;
  private readonly event: EventSenderInterface;

  constructor(api: GraphQlApiInterface, event: EventSenderInterface) {
    this.api = api;
    this.event = event;
  }

  public init(): void {
    this.api.init();
    this.event.init();
  }

  public queueEvent(event: SolveEvent): Promise<any> {
    // Create new objects from the given event.
    // This prevents anyone from meddling with the event.
    return this.event.queueEvent(JSON.parse(JSON.stringify(event)));
  }

  public hasFirstPartyUrls(): boolean {
    return this.api.hasFirstPartyUrls();
  }

  public async queryPublicProfile({
    sessions,
    linking_id
  }: {
    sessions?: Sessions;
    linking_id?: LinkingId | LinkingIdNotGenerated;
  }): Promise<PublicProfileResponse> {
    const forceServerSessions = linking_id === LINKING_ID_NOT_GENERATED;
    const sentLinkingId =
      linking_id === LINKING_ID_NOT_GENERATED ? undefined : linking_id;
    const [, data] = await this.api.sendRequest(
      JSON.stringify({
        query: PUBLIC_PROFILE_QUERY,
        variables: {
          session_id: sessions ? sessions[solveSessionKey] : undefined,
          linking_id: sentLinkingId
        }
      }),
      { forceServerSessions }
    );
    return data.public_profile as PublicProfileResponse;
  }

  public async queryPublicCart(
    id: string,
    provider: string
  ): Promise<PublicCartResponse> {
    const [, data] = await this.api.sendRequest(
      JSON.stringify({
        query: PUBLIC_CART_QUERY,
        variables: {
          id,
          provider
        }
      })
    );

    return data.public_cart;
  }

  public async createOrUpdatePublicCart(
    input: Record<string, any>,
    options?: PublicCartMutationOptions
  ): Promise<PublicCartResponse> {
    const [, data] = await this.api.sendRequest(
      JSON.stringify({
        query: PUBLIC_CART_CREATE_OR_UPDATE_MUTATION,
        variables: {
          input,
          options
        }
      })
    );

    return data.create_or_update_public_cart;
  }

  public async convertPublicCart(
    id: string,
    provider: string,
    orderId?: string,
    options?: PublicCartMutationOptions
  ): Promise<PublicCartResponse> {
    const [, data] = await this.api.sendRequest(
      JSON.stringify({
        query: PUBLIC_CART_CONVERT_MUTATION,
        variables: {
          id,
          provider,
          order_id: orderId,
          options
        }
      })
    );

    return data.convert_public_cart;
  }
}

export type BuildSolveApiOptions = {
  apiKey: string;
  solveStackApiUrl: string;
  firstPartyApiUrls?: string[];
  revision?: string;

  sessions: SessionInterface;
  sentryTrack: SentryTrackError;

  jwt?: JwtManagerInterface;
  api?: GraphQlApiInterface;
  event?: EventSenderInterface;
  storage?: EventQueueInterface;
};

export function buildSolveApiFromSolveConfigObject(
  solveConfig: _Solve,
  {
    revision,
    sessions,
    sentryTrack
  }: {
    revision?: string;
    sessions: SessionInterface;
    sentryTrack: SentryTrackError;
  }
): SolveApiInterface {
  const apiUseHttps = !(solveConfig.apiUrl.indexOf('http://') === 0);
  // Fix (potential) backwards compatibility issue with the API url
  const solveStackApiUrl = createNonEventApiUrl(
    solveConfig.apiUrl,
    apiUseHttps
  );
  const firstPartyApiUrls = fetchFirstPartyDomainList(solveConfig.domains, {
    https: apiUseHttps
  });

  return buildSolveApi({
    apiKey: solveConfig.apiKey,
    solveStackApiUrl,
    firstPartyApiUrls,
    revision,
    sessions,
    sentryTrack
  });
}

export default function buildSolveApi(
  opts: BuildSolveApiOptions
): SolveApiInterface {
  // Extract any managers given in `opts`. We will avoid constructing the given
  //  managers and their dependencies.
  // For example: if an GraphQlApi is given, we don't construct the JwtManager.
  let { jwt, api, storage, event } = opts;
  if (!api) {
    //  Only construct a JwtManager if we need it for the GraphQlApi
    jwt =
      jwt ||
      new JwtManager(opts.sentryTrack, {
        apiKey: opts.apiKey,
        solveStackApiUrl: opts.solveStackApiUrl,
        firstPartyUrls: opts.firstPartyApiUrls,
        revision: opts.revision
      });
    api = new GraphQlApi(jwt, opts.sessions, opts.revision);
  }
  if (!event) {
    //  Only construct a EventQueue if we need it for the EventSender
    storage = storage || new EventQueue(opts.sentryTrack);
    event = new EventSender(api, storage, opts.sessions, opts.sentryTrack);
  }

  return new SolveApi(api, event);
}
