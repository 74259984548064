/// This file contains functions that only need to exist because IE 11 is really
/// old and does not have all of the new utility functions.

/**
 * Hand-rolled implementation of `String.endsWith`.
 *
 * We've had to do this because IE does not support `String.endsWith`
 */
export function endsWith(haystack: string, needle: string): boolean {
  if (needle.length > haystack.length) return false;
  else if (needle.length === haystack.length) return needle === haystack;

  const haystackOffset = haystack.length - needle.length;
  return haystack.substring(haystackOffset) === needle;
}
