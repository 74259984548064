export type PublicCartConvertcart = {
  id: string;
  provider: string;
  orderId?: string;
};

export type PublicCartMutationOptions = {
  session_id?: string;
  occurred_at?: Date;
};

export type PublicCartResponse = Record<string, any> | null;

export class PublicCart {
  public id: string;
  public provider: string;
  public items: {
    price: string;
    quantity: number;
    productId?: string;
    sku?: string;
    title?: string;
  }[];

  public adjustments: {
    description: string;
    amount: string;
  }[];

  public currency: string;
  public attributes: Record<string, any>;
  public orderId?: string;
  public cartUrl?: string;
  public createdAt: Date;
  public status: 'CREATED' | 'ABANDONED' | 'CONVERTED';

  constructor(cart: Record<string, any>) {
    this.id = cart.id;
    this.provider = cart.provider;
    this.items = cart.items
      ? cart.items.map((item: Record<string, any>) => {
          return {
            price: item.price,
            quantity: item.quantity,
            productId: item.product_id,
            sku: item.sku,
            title: item.title
          };
        })
      : [];
    this.adjustments = cart.adjustments;
    this.currency = cart.currency;
    this.attributes = cart.attributes;
    this.orderId = cart.order_id;
    this.cartUrl = cart.cart_url;
    this.createdAt = cart.created_at;
    this.status = cart.status;
  }

  hasBeenConverted(): boolean {
    return this.status === 'CONVERTED';
  }
}
