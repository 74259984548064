import { _Solve } from '../sdk';

/**
 * This is just here for documentation (bear in mind that this value comes from
 * configs in SDK snippets so may not actually match this type in reality).
 */
export type MyShopifyShopDomain = `${string}.myshopify.com`;

export interface WindowShopify {
  shop: MyShopifyShopDomain;
}

export interface WindowShopifyAnalytics {
  meta?: {
    product?: {
      id: number;
      gid: `gid://shopify/Product/${number}`;
    };
  };
}

export interface SolveShopifyShopConfig {
  readonly storeName: string;
  readonly orderNumberFormat?: {
    readonly prefix: string;
    readonly suffix: string;
  };
  readonly useLegacyOrderId?: boolean;
}

/**
 * Instructions on how to set up the Shopify part of the SDK config snippet is here
 * https://solvedata.atlassian.net/wiki/spaces/S/pages/1079509073/Shopify+Store+Code+Configuration+Onboarding
 */
export interface SolveConfigShopify {
  shops: Record<MyShopifyShopDomain, SolveShopifyShopConfig>;
}

export type ShopConfigFetcher = () => SolveShopifyShopConfig | undefined;

export const createShopConfigFetcherFromSdkConfig = (
  sdkConfig: Pick<_Solve, 'config'>,
  { pageWindow = window }: { pageWindow?: Window } = {}
): ShopConfigFetcher => {
  return () => {
    const myShopifyShopDomain = (pageWindow as any).Shopify
      ?.shop as MyShopifyShopDomain;
    if (!myShopifyShopDomain) return undefined;

    return sdkConfig?.config?.shopify?.shops?.[myShopifyShopDomain];
  };
};
