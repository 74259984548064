import { SessionInterface } from '../storage';
import { GraphQlApiInterface, JwtManagerInterface } from './interfaces';

export function addRevision(
  defaultHeaders: Record<string, string>,
  revision?: string
): Record<string, string> {
  if (revision) {
    return { ...defaultHeaders, 'X-Revision': revision };
  } else {
    return { ...defaultHeaders };
  }
}

export default class GraphQlApi implements GraphQlApiInterface {
  private readonly revision?: string;
  private readonly jwt: JwtManagerInterface;
  private readonly sessions: SessionInterface;

  constructor(
    jwt: JwtManagerInterface,
    sessions: SessionInterface,
    revision?: string
  ) {
    this.revision = revision;
    this.jwt = jwt;
    this.sessions = sessions;
  }

  init(): void {
    this.jwt.init();
  }

  public hasFirstPartyUrls(): boolean {
    return this.jwt.hasFirstPartyUrls();
  }

  async sendRequest(
    reqBody: string,
    { forceServerSessions }: { forceServerSessions?: boolean } = {}
  ): Promise<[Response, any]> {
    const { apiUrl, bearerToken, isFirstPartyUrl } =
      await this.jwt.getApiConfiguration();
    const setServerSessions = forceServerSessions || isFirstPartyUrl;
    const headers = addRevision(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`
      },
      this.revision
    );
    if (setServerSessions) {
      headers['X-Server-Sessions'] = 'v1';
    }
    const resp = await fetch(`${apiUrl}/events`, {
      method: 'POST',
      body: reqBody,
      headers,
      credentials: isFirstPartyUrl ? 'include' : undefined
    });
    const respBody = await resp.text();
    if (!resp.ok) {
      if (resp.status === 401) {
        this.jwt.setUnauthenticated(bearerToken);
      }
      // TODO: handle other errors and set the API as unavaliable.
      throw new Error(
        `Request failed ${resp.status} '${resp.statusText}'.\nBody: ${respBody}`
      );
    } else {
      const gqlResp = JSON.parse(respBody);
      if (gqlResp.errors) {
        const errors = Array.from(gqlResp.errors)
          .map((err: any) => (err?.message ? `${err?.message}` : undefined))
          .map(err => err || 'Unsupported error message')
          .sort();

        throw new Error(
          `Request failed with GraphQL Errors ${resp.status} '${
            resp.statusText
          }'.\nErrors: \n  ${errors.join('\n  ')}`
        );
      }
      if (setServerSessions) {
        // Only read this header if we've sent the `X-Server-Sessions` request
        //  header.
        const linkingId = resp.headers.get('x-linking-id');
        if (linkingId) {
          this.sessions.setLinkingId(linkingId);
        }
      }
      return [resp, gqlResp.data];
    }
  }
}
